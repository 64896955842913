import { columnsPodcast } from '@/domain/env/colums-podcast';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { CollectionBase } from '@/domain/types/collections';
import useGetDocuments from '@/domain/use-cases/get-documents';
import Button from '@/presentation/components/button';
import Table from '@/presentation/components/table';
import { FiPlus } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

const PodcastsPage = () => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetDocuments<CollectionBase[]>(
    DOCUMENTS_DB.PODCASTS,
  );

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error}</div>;

  if (!data) return <div>No hay datos</div>;

  const handleRowClick = (row: CollectionBase) => {
    navigate(`/dashboard/podcasts/details/${row.id}`);
  };

  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Audios</h1>

        <Link to="/dashboard/podcasts/add">
          <Button label="Agregar audio" icon={<FiPlus />} onClick={() => {}} />
        </Link>
      </div>

      <Table data={data} columns={columnsPodcast} onRowClick={handleRowClick} />
    </div>
  );
};

export default PodcastsPage;
