import { useAuth } from '@/domain/context/auth';
import { FiLogOut, FiMenu } from 'react-icons/fi';

interface HeaderProps {
  isSidebarOpen: boolean;
  setSidebarOpen: (isSidebarOpen: boolean) => void;
  handleLogout: () => void;
}

const Header = ({
  isSidebarOpen,
  setSidebarOpen,
  handleLogout,
}: HeaderProps) => {
  const { user } = useAuth();

  return (
    <header className="bg-white shadow p-4 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        {!isSidebarOpen && (
          <button
            onClick={() => setSidebarOpen(true)}
            className="text-gray-500 hover:text-gray-800"
          >
            <FiMenu size={20} />
          </button>
        )}
        <h1 className="text-sm font-semibold">
          Bienvenido, {user?.name || 'User'}
        </h1>
      </div>
      <button
        onClick={handleLogout}
        className="px-4 py-2 text-red-500 rounded hover:bg-red-600 hover:text-white flex items-center space-x-2"
      >
        <FiLogOut size={20} />
        <span className="text-sm">Cerrar Sesión</span>
      </button>
    </header>
  );
};

export default Header;
