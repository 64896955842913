import { db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { HistoricalView } from '@/domain/types/historical';
import {
  collection,
  getDocs,
  query,
  QueryConstraint,
  where,
} from 'firebase/firestore';
import { useCallback, useState } from 'react';
import { ReportData } from '../types/intex';

const useReportFilter = () => {
  const [data, setData] = useState<ReportData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async ({
      area,
      department,
      unit,
      position,
      startDate,
      endDate,
    }: {
      area: string;
      department: string;
      unit: string;
      position: string;
      startDate: Date | null;
      endDate: Date | null;
    }) => {
      setIsLoading(true);
      const collectionRef = collection(db, DOCUMENTS_DB.HISTORICAL);
      console.log(area, department, unit, position, startDate, endDate);

      const filters: QueryConstraint[] = [];

      if (area) filters.push(where('area', '==', area));
      if (department) filters.push(where('department', '==', department));
      if (unit) filters.push(where('unit', '==', unit));
      if (position) filters.push(where('position', '==', position));
      if (startDate) filters.push(where('date', '>=', startDate));
      if (endDate) {
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setHours(23, 59, 59, 999);
        filters.push(where('date', '<=', adjustedEndDate));
      }

      const q = query(collectionRef, ...filters);

      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.docs.length);
      const data: HistoricalView[] = [];

      querySnapshot.forEach((doc) => {
        data.push(doc.data() as HistoricalView);
      });

      console.log(data);

      const groupedData = data.reduce((acc, curr) => {
        const existingItem = acc.find(
          (item) => item.collectionId === curr.collectionId,
        );
        if (existingItem) {
          existingItem.total += 1;
        } else {
          acc.push({ ...curr, total: 1, id: (acc.length + 1).toString() });
        }
        return acc;
      }, [] as ReportData[]);

      setData(groupedData);

      setIsLoading(false);
    },
    [],
  );

  const clearData = useCallback(() => {
    setData([]);
  }, []);

  return {
    isLoading,
    data,
    fetchData,
    clearData,
  };
};

export default useReportFilter;
