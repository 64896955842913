import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { CollectionBase } from '@/domain/types/collections';
import useGetDocument from '@/domain/use-cases/get-document';
import AlertMessage from '@/presentation/components/alert-message';
import CommentsDetailDocument from '@/presentation/components/detail-document/comments';
import HeaderDetailDocument from '@/presentation/components/detail-document/header';
import StatsDetailDocument from '@/presentation/components/detail-document/stats';
import FormAddDocument from '@/presentation/components/form-add-document';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const PolicyDetailsPage = () => {
  const [isAlertMessageOpen, setIsAlertMessageOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data,
    error,
    isLoading,
    updateDocument,
    deleteDocument,
    deleteComment,
    comments,
  } = useGetDocument<CollectionBase>(DOCUMENTS_DB.POLICIES, id);

  const handleDelete = async () => {
    try {
      await deleteDocument();
      toast.success('Política eliminada correctamente', {
        theme: 'colored',
      });
      navigate('/dashboard/policies');
    } catch (error) {
      console.log(error);
      toast.error('Error al eliminar la política', { theme: 'colored' });
    }
  };

  const handleSave = async (updateData: CollectionBase) => {
    try {
      const updatedData = { ...data, ...updateData };
      console.log(updatedData);
      await updateDocument(updatedData);
      toast.success('Política actualizada correctamente', {
        theme: 'colored',
      });
    } catch (error) {
      console.log(error);
      toast.error('Error al actualizar la política', { theme: 'colored' });
    }
  };

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error}</div>;

  if (!data) return <div>No data</div>;

  return (
    <div className="p-4 flex flex-row md:flex-col gap-8">
      <HeaderDetailDocument
        title={data.title}
        handleDelete={() => setIsAlertMessageOpen(true)}
      />

      <StatsDetailDocument data={data} />

      <FormAddDocument onSave={handleSave} document={data} />

      <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
        <CommentsDetailDocument data={comments} deleteComment={deleteComment} />
      </div>

      <AlertMessage
        title="Eliminar Política"
        message="¿Estás seguro de querer eliminar esta política?"
        onConfirm={handleDelete}
        onCancel={() => setIsAlertMessageOpen(false)}
        isOpen={isAlertMessageOpen}
      />
    </div>
  );
};

export default PolicyDetailsPage;
