import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './domain/context/auth';
import DashboardLayout from './presentation/components/layout/dashboard';
import Login from './presentation/pages/auth/login';
import CriticalStandarsPage from './presentation/pages/critical-standars';
import CriticalStandardsAddPage from './presentation/pages/critical-standars/add';
import CriticalStandardsDetailsPage from './presentation/pages/critical-standars/detail';
import NewslettersPage from './presentation/pages/newsletters';
import NewsletterAddPage from './presentation/pages/newsletters/add';
import NewsletterDetailsPage from './presentation/pages/newsletters/detail';
import PodcastsPage from './presentation/pages/podcasts';
import PodcastAddPage from './presentation/pages/podcasts/add';
import PodcastDetailsPage from './presentation/pages/podcasts/detail';
import PoliciesPage from './presentation/pages/policies';
import PolicyAddPage from './presentation/pages/policies/add';
import PolicyDetailsPage from './presentation/pages/policies/detail';
import ReleasesPage from './presentation/pages/releases';
import ReleaseAddPage from './presentation/pages/releases/add';
import ReleaseDetailsPage from './presentation/pages/releases/detail';
import ReportsPages from './presentation/pages/reports';
import ReportAddPage from './presentation/pages/reports/add';
import ReportDetailsPage from './presentation/pages/reports/detail';
import UsersPage from './presentation/pages/users';
import VideosPage from './presentation/pages/videos';
import VideoAddPage from './presentation/pages/videos/add';
import VideoDetailsPage from './presentation/pages/videos/detail';
import PoliciesPrivacityPage from './presentation/pages/policies-privacity';
import UsersUploadPage from './presentation/pages/users/users-upload';
import ReportByFilterPage from './presentation/pages/statistics/report-by-filter';
import { DashboardPage } from './presentation/pages/dashboard';
import ReportByEmployeePage from './presentation/pages/statistics/report-by-employee';
import UsersUpdatePage from './presentation/pages/users/users-update';

const App: React.FC = () => {
  const { user, isLoadingUser } = useAuth();

  if (isLoadingUser) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/dashboard/home" /> : <Login />}
        />
        <Route path="privacy-policy" element={<PoliciesPrivacityPage />} />
        <Route
          path="/dashboard"
          element={
            user && user.role === 'administrator' ? (
              <DashboardLayout />
            ) : (
              <Navigate to="/" />
            )
          }
        >
          <Route path="home" element={<DashboardPage />} />
          <Route path="newsletters" element={<NewslettersPage />} />
          <Route path="newsletters/add" element={<NewsletterAddPage />} />
          <Route
            path="newsletters/details/:id"
            element={<NewsletterDetailsPage />}
          />
          <Route path="policies" element={<PoliciesPage />} />
          <Route path="policies/add" element={<PolicyAddPage />} />
          <Route path="policies/details/:id" element={<PolicyDetailsPage />} />
          <Route path="critical-standars" element={<CriticalStandarsPage />} />
          <Route
            path="critical-standars/add"
            element={<CriticalStandardsAddPage />}
          />
          <Route
            path="critical-standars/details/:id"
            element={<CriticalStandardsDetailsPage />}
          />
          <Route path="releases" element={<ReleasesPage />} />
          <Route path="releases/add" element={<ReleaseAddPage />} />
          <Route path="releases/details/:id" element={<ReleaseDetailsPage />} />
          <Route path="reports" element={<ReportsPages />} />
          <Route path="reports/add" element={<ReportAddPage />} />
          <Route path="reports/details/:id" element={<ReportDetailsPage />} />
          <Route path="podcasts" element={<PodcastsPage />} />
          <Route path="podcasts/add" element={<PodcastAddPage />} />
          <Route path="podcasts/details/:id" element={<PodcastDetailsPage />} />
          <Route path="videos" element={<VideosPage />} />
          <Route path="videos/add" element={<VideoAddPage />} />
          <Route path="videos/details/:id" element={<VideoDetailsPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="users/upload" element={<UsersUploadPage />} />
          <Route path="users/update" element={<UsersUpdatePage />} />
          <Route
            path="statistics/report-by-filter"
            element={<ReportByFilterPage />}
          />
          <Route
            path="statistics/report-by-employee"
            element={<ReportByEmployeePage />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
