import Spinner from '@/presentation/components/spinner';
import Table from '@/presentation/components/table';
import FiltersReports from './components/filters';
import columnsDashboard from './env/columns';
import useReportFilter from './hooks/use-report';

export default function ReportByFilterPage() {
  const { isLoading, data, fetchData, clearData } = useReportFilter();

  return (
    <div className="flex flex-col gap-4 p-4">
      <h1 className="text-3xl font-bold">Reporte por filtros</h1>

      <FiltersReports fetchData={fetchData} data={data} clearData={clearData} />

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <>
          <Table columns={columnsDashboard} data={data} />
        </>
      )}
    </div>
  );
}
