export enum DOCUMENTS_DB {
  USERS_ADMIN = 'users_admin',
  USERS = 'users',
  NEWSLETTERS = 'newsletters',
  POLICIES = 'policies',
  REPORTS = 'reports',
  RELEASES = 'releases',
  CRITICAL_STANDARS = 'critical_standars',
  PODCASTS = 'podcasts',
  VIDEOS = 'videos',
  HISTORICAL = 'historical',
  CONFIG = 'config',
}
