import React from 'react';

interface DrawerProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  return (
    <div
      className={`fixed inset-0 z-50 bg-black  bg-opacity-70 transition-opacity p-4 flex justify-end ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`w-full md:w-1/3 lg:w-1/4 bg-white shadow-lg h-full overflow-y-auto transform transition-transform rounded-2xl ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className="flex justify-between items-center p-4">
          <h2 className="text-2xl">{title}</h2>
          <button onClick={onClose} className="text-2xl">
            &times;
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};
export default Drawer;
