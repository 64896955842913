// Import the functions you need from the SDKs you need
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBYrpK0_sQ8PsLapV5z5-PfTh35iBjAKAE',
  authDomain: 'seguridad-industrial-drummond.firebaseapp.com',
  projectId: 'seguridad-industrial-drummond',
  storageBucket: 'seguridad-industrial-drummond.firebasestorage.app',
  messagingSenderId: '520805155196',
  appId: '1:520805155196:web:de182cafe7215d4b2364f9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
