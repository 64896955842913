import Spinner from '@/presentation/components/spinner';
import Table from '@/presentation/components/table';
import FiltersReportEmployee from './components/filters/filters';
import { columns } from './env/columns';
import useReportEmployee from './hooks/use-report-employee';

const ReportByEmployeePage = () => {
  const { isLoading, data, fetchData, clearData } = useReportEmployee();

  return (
    <div className="flex flex-col gap-4 p-4">
      <h1 className="text-3xl font-bold">Reporte por empleado</h1>

      <FiltersReportEmployee
        fetchData={fetchData}
        data={data}
        clearData={clearData}
      />

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <>
          <Table columns={columns} data={data} />
        </>
      )}
    </div>
  );
};

export default ReportByEmployeePage;
