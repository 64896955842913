import Button from '../button';

interface AlertMessageProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const AlertMessage = ({
  title,
  message,
  onConfirm,
  onCancel,
  isOpen,
}: AlertMessageProps) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <p className="text-gray-600 mb-6">{message}</p>

        <div className="flex justify-end gap-4">
          <Button label="Cancelar" variant="primary" onClick={onCancel} />
          <Button label="Confirmar" variant="secondary" onClick={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
