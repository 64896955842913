import React, { useState } from 'react';
import { MdEditDocument, MdPictureAsPdf } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface PdfUploadProps {
  onFileChange: (file: File | null) => void;
  pdf?: string | null;
  id: string;
}

const PdfUpload: React.FC<PdfUploadProps> = ({ onFileChange, pdf, id }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
      onFileChange(file);
    } else {
      alert('Por favor, selecciona un archivo PDF.');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files?.[0];
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file);
      onFileChange(file);
    } else {
      alert('Por favor, arrastra un archivo PDF.');
    }
  };

  const RenderContent = () => {
    if (selectedFile) {
      return (
        <>
          <MdPictureAsPdf size={60} className="text-red-500" />
          <div className="text-center">
            <p className="text-lg font-semibold text-gray-700">
              {selectedFile.name}
            </p>
          </div>
        </>
      );
    }
    if (pdf) {
      return (
        <>
          <MdPictureAsPdf size={60} className="text-red-500" />
          <div className="text-center">
            <Link to={pdf} target="_blank" className="text-blue-500">
              Ver PDF
            </Link>
            <p className="text-sm text-gray-500">
              Haz clic para cambiar el archivo
            </p>
          </div>
        </>
      );
    }
    return (
      <>
        <MdEditDocument size={60} className="text-gray-500" />
        <div className="text-center">
          <p className="text-lg font-semibold text-gray-700">
            Arrastra y suelta tu PDF aquí
          </p>
          <p className="text-sm text-gray-500">o haz clic para seleccionar</p>
        </div>
      </>
    );
  };

  return (
    <div
      className="flex flex-col items-center space-y-4"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 w-full bg-white hover:border-blue-500 transition-colors">
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          className="hidden"
          id={id}
        />
        <label
          htmlFor={id}
          className="flex flex-col items-center space-y-4 cursor-pointer"
        >
          <RenderContent />
        </label>
      </div>
    </div>
  );
};

export default PdfUpload;
