import { db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { User } from '@/domain/types/user';
import AlertMessage from '@/presentation/components/alert-message';
import Button from '@/presentation/components/button';
import ProgressBar from '@/presentation/components/progress-bar';
import Table from '@/presentation/components/table';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  or,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { FaDownload, FaFileExcel, FaSave, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { read, utils } from 'xlsx';
import { columnsUserUpload } from './columns';

export type Status = 'success' | 'error' | 'pending';

export type UserUpload = User & {
  message: string;
  status: Status;
};

const UsersUpdatePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newUsers, setNewUsers] = useState<UserUpload[]>([]);
  const [isOpenModalAlert, setIsOpenModalAlert] = useState(false);
  const [progress, setProgress] = useState(0);
  const [createUsersFile, setCreateUsersFile] = useState<string>('');

  const getCreateUsersFile = async () => {
    const docRef = doc(db, DOCUMENTS_DB.CONFIG, 'files');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      console.log(data);
      setCreateUsersFile(data.updateUsersFile || '');
    }
  };

  useEffect(() => {
    getCreateUsersFile();
  }, []);

  const updateUser = async (
    user: UserUpload,
  ): Promise<{ status: Status; message: string }> => {
    const collectionRef = collection(db, DOCUMENTS_DB.USERS);
    const filter = query(
      collectionRef,
      or(
        where('document', '==', user.document),
        where('document', '==', parseInt(user.document)),
      ),
    );
    const querySnapshot = await getDocs(filter);

    if (querySnapshot.empty) {
      return {
        status: 'error',
        message: 'El documento no existe',
      };
    }

    const docRef = doc(db, DOCUMENTS_DB.USERS, querySnapshot.docs[0].id);

    await updateDoc(docRef, {
      isEnabled: user.isEnabled,
    });

    return {
      status: 'success',
      message: 'Usuario actualizado correctamente',
    };
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      setIsLoading(true);
      const file = event.target.files?.[0];

      if (!file) return;

      // Leer el archivo Excel
      const data = await file.arrayBuffer();
      const workbook = read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: Record<string, string>[] = utils.sheet_to_json(worksheet);

      if (jsonData.length === 0) {
        toast.error('No se encontraron usuarios en el archivo', {
          theme: 'colored',
        });
        return;
      }

      if (jsonData.length > 200) {
        toast.error('El archivo no puede tener más de 200 usuarios', {
          theme: 'colored',
        });
        return;
      }

      for (const row of jsonData) {
        const splitName = row['Name'].split(',');
        const isEnabled = row['Estado'] === 'Activo';

        const userData: UserUpload = {
          name: splitName[1].trim() || '',
          lastname: splitName[0].trim() || '',
          document: row['NID'].toString() || '',
          email: row['Correo'] || '',
          department: row['Department'] || '',
          area: row['Area'] || '',
          position: row['General Position'] || '',
          unit: row['Unit'] || '',
          isEnabled,
          role: 'employee',
          id: row['NID'].toString() || '',
          message: '',
          status: 'pending',
        };

        setNewUsers((prev) => [...prev, userData]);
      }
    } catch (error) {
      console.error('Error al importar usuarios:', error);
      toast.error('Error al importar usuarios', { theme: 'colored' });
    } finally {
      setIsLoading(false);
    }
  };

  const saveUsers = async () => {
    const usersSaved: UserUpload[] = [];
    setIsOpenModalAlert(false);
    setIsLoading(true);
    let count = 0;
    for (const user of newUsers) {
      count++;
      const response = await updateUser(user);
      usersSaved.push({ ...user, ...response });
      setProgress(count);
    }
    setNewUsers(usersSaved);
    setIsLoading(false);
  };

  if (newUsers.length === 0)
    return (
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col items-center gap-2">
          <h1 className="text-2xl font-bold">Activar o desactivar empleados</h1>
          <div className="flex items-start gap-2">
            <div className="flex flex-col items-center gap-2">
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileUpload}
                className="hidden"
                id="file-upload-employees"
                disabled={isLoading}
              />
              <label htmlFor="file-upload-employees">
                <Button
                  label={isLoading ? 'Importando...' : 'Importar Empleados'}
                  icon={<FaFileExcel />}
                  isLoading={isLoading}
                  disabled={isLoading}
                  className="cursor-pointer bg-green-600 hover:bg-green-700"
                  type="button"
                  onClick={() => {
                    const fileInput = document.getElementById(
                      'file-upload-employees',
                    ) as HTMLInputElement;
                    fileInput.click();
                  }}
                />
              </label>
            </div>
            <div className="flex flex-col items-center gap-2">
              <Link to={createUsersFile} target="_blank">
                <Button
                  label="Descargar Plantilla"
                  variant="secondary"
                  className="bg-blue-600 hover:bg-blue-700"
                  icon={<FaDownload />}
                />
              </Link>
            </div>
          </div>
          <span className="text-sm text-gray-500">
            El archivo no puede tener más de 200 usuarios
          </span>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold">Empleados importados</h1>
          <span className="text-sm text-gray-500">
            {newUsers.length} empleados importados
          </span>
          <ProgressBar progress={progress} total={newUsers.length} />
        </div>

        <div className="flex gap-2">
          <Button
            label="Cancelar"
            icon={<FaTimes />}
            variant="secondary"
            onClick={() => setNewUsers([])}
            disabled={isLoading}
          />
          <Button
            label="Cambiar estado de empleados"
            icon={<FaSave />}
            onClick={() => setIsOpenModalAlert(true)}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Table columns={columnsUserUpload} data={newUsers} />
      <AlertMessage
        isOpen={isOpenModalAlert}
        title="Cambiar estado de empleados"
        message="Estás a punto de cambiar el estado de los empleados, esta acción es irreversible"
        onConfirm={() => saveUsers()}
        onCancel={() => setIsOpenModalAlert(false)}
      />
    </div>
  );
};

export default UsersUpdatePage;
