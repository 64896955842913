import { getAuth, signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/header';
import Sidebar from './components/sidebar';

const DashboardLayout: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const auth = getAuth();

  const handleLogout = async () => {
    await signOut(auth);
    window.location.href = '/'; // Redirect to login
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      {isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      )}

      {/* Main content */}
      <div
        className={`flex-1 flex flex-col bg-gray-100 transition-all duration-300 min-w-0`}
      >
        {/* Header */}

        <Header
          isSidebarOpen={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
          handleLogout={handleLogout}
        />

        {/* Content */}
        <main className="flex-1 p-6 overflow-y-auto w-full">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
