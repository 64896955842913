import React from 'react';

export type Column<T> = {
  header: string;
  accessor: keyof T;
  render?: (value: T[keyof T] | undefined, row: T) => React.ReactNode;
  isSortable?: boolean;
};

type TableProps<T> = {
  data: T[]; // Datos de la tabla
  columns: Column<T>[]; // Configuración de columnas
  className?: string; // Clases CSS adicionales
  onRowClick?: (row: T) => void; // Evento al hacer clic en una fila
};

const Table = <T,>({
  data,
  columns,
  className,
  onRowClick,
}: TableProps<T>): JSX.Element => {
  const [sortConfig, setSortConfig] = React.useState<{
    key: keyof T | null;
    direction: 'asc' | 'desc' | null;
  }>({ key: null, direction: null });

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key!];
      const bValue = b[sortConfig.key!];

      if (aValue === bValue) return 0;
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      const result = aValue < bValue ? -1 : 1;
      return sortConfig.direction === 'asc' ? result : -result;
    });
  }, [data, sortConfig]);

  const handleSort = (column: Column<T>) => {
    if (!column.isSortable) return;

    setSortConfig((prevSort) => {
      if (prevSort.key === column.accessor) {
        if (prevSort.direction === 'asc') {
          return { key: column.accessor, direction: 'desc' };
        }
        return { key: null, direction: null };
      }
      return { key: column.accessor, direction: 'asc' };
    });
  };

  return (
    <div className={`overflow-x-auto shadow-md ${className}`}>
      <table className="min-w-full border-collapse border border-gray-300 bg-white">
        <thead className="bg-gray-100">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={`border border-gray-300 px-4 py-2 text-center font-semibold text-sm ${
                  column.isSortable ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleSort(column)}
              >
                <div className="flex items-center justify-center gap-2">
                  {column.header}
                  {column.isSortable && (
                    <span className="text-gray-400">
                      {sortConfig.key === column.accessor
                        ? sortConfig.direction === 'asc'
                          ? '↑'
                          : '↓'
                        : '↕'}
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`hover:bg-gray-50 ${
                onRowClick ? 'cursor-pointer' : ''
              }`}
              onClick={() => onRowClick?.(row)}
            >
              {columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className="border border-gray-300 px-4 py-2 text-xs"
                >
                  {column.render
                    ? column.render(row[column.accessor], row)
                    : row[column.accessor]?.toString()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
