import Table from '@/presentation/components/table';
import { useEffect } from 'react';
import { columns } from './columns';
import Totals from './components/totals';
import useDashboard from './hooks/use-dashboard';

export const DashboardPage = () => {
  const {
    data,
    fetchData,
    totalFavorites,
    totalComments,
    totalViews,
    totalDownloads,
  } = useDashboard();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="flex flex-col gap-4 p-4">
      <h1 className="text-3xl font-bold">Dashboard</h1>

      <Totals
        totalFavorites={totalFavorites}
        totalComments={totalComments}
        totalViews={totalViews}
        totalDownloads={totalDownloads}
      />

      <Table columns={columns} data={data} />
    </div>
  );
};
