import { COLLECTION_NAME_MAP } from '@/domain/env/collection-name-map';
import { Column } from '@/presentation/components/table';
import { ReportEmployee } from '../types';

export const columns: Column<ReportEmployee>[] = [
  {
    header: 'Título',
    accessor: 'title',
  },
  {
    header: 'Tipo',
    accessor: 'collectionName',
    render: (value) => {
      return COLLECTION_NAME_MAP[value as keyof typeof COLLECTION_NAME_MAP];
    },
  },
  {
    header: 'Documento',
    accessor: 'document',
  },
  {
    header: 'Nombre',
    accessor: 'name',
  },
  {
    header: 'Apellido',
    accessor: 'lastname',
  },
  {
    header: 'Departamento',
    accessor: 'department',
  },
  {
    header: 'Área',
    accessor: 'area',
  },
  {
    header: 'Unidad',
    accessor: 'unit',
  },
  {
    header: 'Cargo',
    accessor: 'position',
  },
  {
    header: 'Vistas',
    accessor: 'views',
    isSortable: true,
    render: (value) => {
      return <div className="flex justify-center items-center">{value}</div>;
    },
  },
];
