import { useFormContext, Controller } from 'react-hook-form';
import { Input } from '../input';

type InputFormProps = {
  name: string;
  placeholder?: string;
  type?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
};

export const InputForm: React.FC<InputFormProps> = ({
  name,
  placeholder,
  type = 'text',
  className,
  label,
  disabled,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col space-y-2">
      {label && <span className="text-sm text-gray-500">{label}</span>}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            placeholder={placeholder}
            type={type}
            className={`${className} ${disabled ? 'bg-gray-100' : ''} ${errors[name] ? 'border-red-500' : ''}`}
            disabled={disabled}
          />
        )}
      />
      {errors[name] && (
        <span className="text-sm text-red-500">
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
};
