interface TotalsProps {
  totalFavorites: number;
  totalComments: number;
  totalViews: number;
  totalDownloads: number;
}

const Totals = ({
  totalFavorites,
  totalComments,
  totalViews,
  totalDownloads,
}: TotalsProps) => {
  return (
    <div className="flex flex-col gap-4">
      <span className="text-sm text-gray-500">Totales generales</span>
      <div className="flex justify-between items-center gap-2">
        <div className="flex flex-col items-center justify-between bg-white p-4 gap-2 rounded-md flex-1 shadow-md min-h-[110px]">
          <span className="text-xs text-gray-500">Vistas</span>
          <span className="text-3xl font-bold">{totalViews}</span>
        </div>
        <div className="flex flex-col items-center justify-between bg-white p-4 gap-2 rounded-md flex-1 shadow-md min-h-[110px]">
          <span className="text-xs text-gray-500">Favoritos</span>
          <span className="text-3xl font-bold">{totalFavorites}</span>
        </div>
        <div className="flex flex-col items-center justify-between bg-white p-4 gap-2 rounded-md flex-1 shadow-md min-h-[110px]">
          <span className="text-xs text-gray-500">Comentarios</span>
          <span className="text-3xl font-bold">{totalComments}</span>
        </div>
        <div className="flex flex-col items-center justify-between bg-white p-4 gap-2 rounded-md flex-1 shadow-md min-h-[110px]">
          <span className="text-xs text-gray-500">Descargas</span>
          <span className="text-3xl font-bold">{totalDownloads}</span>
        </div>
      </div>
    </div>
  );
};

export default Totals;
