import { Controller, useFormContext } from 'react-hook-form';

type TextAreaFormProps = {
  name: string;
  placeholder?: string;
  type?: string;
};

export const TextAreaForm: React.FC<TextAreaFormProps> = ({
  name,
  placeholder,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col space-y-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <textarea
            value={field.value}
            onChange={field.onChange}
            placeholder={placeholder}
            rows={10}
            className={`px-3 py-2 border rounded focus:ring-2 focus:ring-primary focus:ring-opacity-50 ${errors[name] ? 'border-red-500' : ''}`}
          />
        )}
      />
      {errors[name] && (
        <span className="text-sm text-red-500">
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
};
