import React from 'react';
import Spinner from '../spinner';

type ButtonProps = {
  label: string;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'ghost' | 'link' | 'danger';
  icon?: React.ReactNode;
  isLoading?: boolean;
  positionIcon?: 'left' | 'right';
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  className,
  type = 'button',
  variant = 'primary',
  icon,
  isLoading,
  positionIcon = 'left',
  disabled = false,
}) => {
  const baseStyles =
    'flex items-center justify-center h-12 px-4 py-2 rounded font-medium  gap-2 disabled:opacity-50 disabled:cursor-not-allowed';

  const variantStyles = {
    primary: 'bg-primary text-white hover:bg-primary-dark',
    secondary: 'bg-gray-500 text-white hover:bg-gray-600',
    ghost: 'bg-transparent text-primary hover:bg-primary-dark hover:text-white',
    link: 'text-blue-500 underline hover:text-blue-600',
    danger: 'bg-red-500 text-white hover:bg-red-600',
  };

  const iconStyles = {
    primary: 'text-white',
    secondary: 'text-white',
    ghost: 'text-gray-700',
    link: 'text-blue-500',
    danger: 'text-white',
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseStyles} ${variantStyles[variant]} ${className} `}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <Spinner size="small" className={iconStyles[variant]} />
      ) : (
        <>
          {icon && positionIcon === 'left' && icon}
          {label}
          {icon && positionIcon === 'right' && icon}
        </>
      )}
    </button>
  );
};

export default Button;
