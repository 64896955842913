import { db } from '@/data/firebase/config';
import { columnsUser } from '@/domain/env/colums-user';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { User } from '@/domain/types/user';
import Button from '@/presentation/components/button';
import Drawer from '@/presentation/components/drawer';
import Spinner from '@/presentation/components/spinner';
import Table from '@/presentation/components/table';
import {
  collection,
  DocumentData,
  DocumentSnapshot,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { FaArrowDown, FaFileExcel } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import AddUserForm from './form-add';

const UsersPage = () => {
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [user, setUser] = useState<User>();
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastVisible, setLastVisible] =
    useState<DocumentSnapshot<DocumentData> | null>(null);
  const [limitRows, setLimitRows] = useState(10);
  const [dataFiltered, setDataFiltered] = useState<User[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<string>('document');

  const searchOptions = [
    { label: 'Documento', value: 'document' },
    { label: 'Nombre', value: 'name' },
    { label: 'Apellido', value: 'lastname' },
    { label: 'Email', value: 'email' },
    { label: 'Área', value: 'area' },
    { label: 'Departamento', value: 'department' },
    { label: 'Cargo', value: 'position' },
    { label: 'Unidad', value: 'unit' },
    { label: 'Rol', value: 'role' },
  ];

  const handleAddUser = () => {
    setUser({
      id: '',
      document: '',
      name: '',
      lastname: '',
      email: '',
      role: 'employee',
      isEnabled: false,
      department: '',
      area: '',
      position: '',
      unit: '',
    });
    setIsEdit(false);
    setIsOpenAddUser(true);
  };

  const handleEditUser = (user: User) => {
    setUser(user);
    setIsEdit(true);
    setIsOpenAddUser(true);
  };

  const fetchData = useCallback(
    async (lastVisibleProp: DocumentSnapshot<DocumentData> | null = null) => {
      setIsLoading(true);

      const collectionRef = collection(db, DOCUMENTS_DB.USERS);

      const queryRef = query(
        collectionRef,
        orderBy('name'),
        limit(limitRows),
        startAfter(lastVisibleProp),
      );

      const snapshot = await getDocs(queryRef);

      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        document: doc.data().document,
        name: doc.data().name,
        lastname: doc.data().lastname,
        email: doc.data().email,
        role: doc.data().role || 'employee',
        isEnabled: doc.data().isEnabled,
        area: doc.data().area,
        department: doc.data().department,
        position: doc.data().position,
        unit: doc.data().unit,
      })) as User[];

      if (lastVisibleProp) {
        setData((prev) => [...prev, ...newData]);
      } else {
        setData(newData);
      }

      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setIsLoading(false);
    },
    [limitRows],
  );

  const handleSearch = async (searchTerm: string) => {
    setIsLoading(true);
    setDataFiltered([]);

    if (!searchTerm.trim()) {
      fetchData();
      return;
    }

    const collectionRef = collection(db, DOCUMENTS_DB.USERS);
    let queryRef;

    if (searchCriteria === 'document') {
      queryRef = query(
        collectionRef,
        or(
          where('document', '==', searchTerm),
          where('document', '==', parseInt(searchTerm)),
        ),
      );
    } else {
      queryRef = query(
        collectionRef,
        orderBy(searchCriteria),
        where(searchCriteria, '>=', searchTerm),
        where(searchCriteria, '<=', searchTerm + '\uf8ff'),
      );
    }

    try {
      const snapshot = await getDocs(queryRef);
      const filteredUsers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as User[];

      setDataFiltered(filteredUsers);
    } catch (error) {
      console.error('Error en la búsqueda:', error);
      setDataFiltered([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaved = () => {
    setIsOpenAddUser(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!data) return <div>No hay datos</div>;

  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Empleados</h1>
        <div className="flex items-center gap-2">
          <Link to="/dashboard/users/update">
            <Button
              label="Activar o desactivar empleados"
              icon={<FaFileExcel />}
              onClick={() => {}}
              variant="secondary"
            />
          </Link>
          <Link to="/dashboard/users/upload">
            <Button
              label="Cargar empleados"
              icon={<FaFileExcel />}
              onClick={() => {}}
              variant="primary"
            />
          </Link>

          <Button
            label="Agregar empleado"
            icon={<FiPlus />}
            onClick={handleAddUser}
          />
        </div>
      </div>

      <div className="flex justify-end items-center gap-8">
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">Buscar por</span>
          <select
            className="border border-gray-300 bg-white rounded-md p-2 text-sm"
            onChange={(e) => setSearchCriteria(e.target.value)}
            value={searchCriteria}
          >
            {searchOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500"></span>
          <input
            type="text"
            className="border border-gray-300 bg-white rounded-md p-2 text-sm"
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Escribe para buscar"
          />
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">Cargar # de empleados</span>
          <select
            className="border border-gray-300 bg-white rounded-md p-2"
            onChange={(e) => setLimitRows(Number(e.target.value))}
            value={limitRows}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      <div>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spinner />
          </div>
        ) : (
          <>
            <Table
              columns={columnsUser}
              data={dataFiltered.length > 0 ? dataFiltered : data}
              onRowClick={handleEditUser}
            />
            <div className="flex justify-center pt-4">
              <Button
                label="Mostrar más"
                variant="ghost"
                icon={<FaArrowDown />}
                positionIcon="right"
                onClick={() => fetchData(lastVisible)}
                className="w-fit"
              />
            </div>
          </>
        )}
      </div>

      <Drawer
        isOpen={isOpenAddUser}
        onClose={() => setIsOpenAddUser(false)}
        title={isEdit ? 'Editar empleado' : 'Agregar empleado'}
      >
        <AddUserForm user={user} isEdit={isEdit} onSaved={handleSaved} />
      </Drawer>
    </div>
  );
};

export default UsersPage;
