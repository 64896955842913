import { Column } from '@/presentation/components/table';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { UserUpload } from '.';

export const columnsUserUpload: Column<UserUpload>[] = [
  {
    header: 'NID',
    accessor: 'id',
  },
  {
    header: 'Nombre',
    accessor: 'name',
  },
  {
    header: 'Correo',
    accessor: 'email',
  },
  {
    header: 'Departamento',
    accessor: 'department',
  },
  {
    header: 'Area',
    accessor: 'area',
  },
  {
    header: 'Cargo',
    accessor: 'position',
  },
  {
    header: 'Unidad',
    accessor: 'unit',
  },
  {
    header: 'Mensaje',
    accessor: 'message',
  },
  {
    header: 'Estado',
    accessor: 'status',
    render: (row) => {
      if (row && typeof row === 'string') {
        return row === 'success' ? (
          <FaCheckCircle className="text-green-500" />
        ) : row === 'error' ? (
          <FaExclamationTriangle className="text-red-500" />
        ) : null;
      }
      return null;
    },
  },
];
