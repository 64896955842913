import { Column } from '@/presentation/components/table';
import { FaVideo } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { CollectionBase } from '../types/collections';

export const columnsVideos: Column<CollectionBase>[] = [
  { header: 'Título', accessor: 'title' },
  {
    header: 'Descripción',
    accessor: 'description',
    render: (value) => {
      if (value && typeof value === 'string') {
        return (
          <span className="text-sm truncate block max-w-[400px]">{value}</span>
        );
      }
      return <span className="text-sm">No hay descripción</span>;
    },
  },
  {
    header: 'Creado el',
    accessor: 'createdAt',
    render: (value) => {
      if (value && typeof value === 'object' && 'seconds' in value) {
        return (
          <span className="text-sm">
            {new Date(value.seconds! * 1000).toLocaleDateString()}
          </span>
        );
      }
      return <span className="text-sm">Desconocido</span>;
    },
  },
  {
    header: 'Vistas',
    accessor: 'views',
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Descargas',
    accessor: 'downloads',
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Favoritos',
    accessor: 'favorites',
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Comentarios',
    accessor: 'comments',
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Tags',
    accessor: 'tags',
    render: (value) => (
      <span className="text-sm">
        {value && Array.isArray(value) ? value.join(', ') : 'Sin tags'}
      </span>
    ),
  },
  {
    header: 'Video',
    accessor: 'uri',
    render: (value) =>
      value ? (
        <Link to={value.toString()} target="_blank" rel="noreferrer">
          <div className="flex justify-center items-center">
            <FaVideo className="text-red-500" size={24} />
          </div>
        </Link>
      ) : (
        <div className="flex justify-center items-center">
          <FaVideo className="text-gray-500" size={24} />
        </div>
      ),
  },
];
