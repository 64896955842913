import { COLLECTION_NAME_MAP } from '@/domain/env/collection-name-map';
import { Column } from '@/presentation/components/table';
import { ReportData } from '@/presentation/pages/statistics/report-by-filter/types/intex';

const columnsDashboard: Column<ReportData>[] = [
  {
    header: 'ID',
    accessor: 'id',
    render: (value) => {
      return <div className="flex justify-center items-center">{value}</div>;
    },
  },

  {
    header: 'Título',
    accessor: 'title',
  },
  {
    header: 'Tipo',
    accessor: 'collectionName',
    render: (value) => {
      return COLLECTION_NAME_MAP[value as keyof typeof COLLECTION_NAME_MAP];
    },
  },
  {
    header: 'Total de vistas',
    accessor: 'total',
    isSortable: true,
    render: (value) => {
      return (
        <div className="flex justify-center items-center">
          <span className="text-center">{value}</span>
        </div>
      );
    },
  },
];

export default columnsDashboard;
