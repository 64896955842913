import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '../select';

type SelectFormProps = {
  name: string;
  placeholder?: string;
  className?: string;
  label?: string;
  options: { value: string; label: string }[];
};

export const SelectForm: React.FC<SelectFormProps> = ({
  name,
  placeholder,
  className,
  label,
  options,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col space-y-2">
      {label && <span className="text-sm text-gray-500">{label}</span>}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            placeholder={placeholder}
            options={options}
            className={`${className} ${errors[name] ? 'border-red-500' : ''}`}
          />
        )}
      />
      {errors[name] && (
        <span className="text-sm text-red-500">
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
};
