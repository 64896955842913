import { db } from '@/data/firebase/config';
import { CollectionBase, CommentDocument } from '@/domain/types/collections';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  Unsubscribe,
  updateDoc,
} from 'firebase/firestore';
import React, { useCallback, useEffect, useRef } from 'react';

const useGetDocument = <T>(document: string, id?: string) => {
  const [data, setData] = React.useState<T | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const unsubscribeRef = useRef<Unsubscribe | null>(null);
  const [comments, setComments] = React.useState<CommentDocument[]>([]);

  const fetchData = useCallback(async () => {
    if (!id) return;
    console.log('fetchData', id);
    setIsLoading(true);
    if (unsubscribeRef.current) {
      unsubscribeRef.current(); // Unsubscribe if already set
    }
    const documentRef = doc(db, document, id);

    unsubscribeRef.current = onSnapshot(
      documentRef,
      (doc) => {
        setData({ ...doc.data(), id: doc.id } as T);
        setIsLoading(false);
      },
      (error) => {
        setError(error.message);
        setIsLoading(false);
      },
    );
  }, [document, id]);

  const updateComments = async (comments: CommentDocument[]) => {
    if (!id) return;
    const commentRef = doc(db, document, id);
    await updateDoc(commentRef, { comments });
  };

  const updateDocument = async (data: CollectionBase) => {
    if (!id) return;
    const documentRef = doc(db, document, id);
    await updateDoc(documentRef, { ...data });
  };

  const deleteDocument = async () => {
    if (!id) return;
    const documentRef = doc(db, document, id);
    await deleteDoc(documentRef);
  };

  const getComments = useCallback(async () => {
    if (!id) return;
    const commentRef = collection(db, document, id, 'comments');
    const commentSnapshot = await getDocs(commentRef);
    setComments(
      commentSnapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          }) as CommentDocument,
      ),
    );
  }, [document, id]);

  const deleteComment = async (id: string) => {
    if (!id) return;
    const commentRef = doc(db, document, id, 'comments', id);
    await deleteDoc(commentRef);
  };

  useEffect(() => {
    fetchData();
    getComments();
    return () => {
      unsubscribeRef.current && unsubscribeRef.current();
    };
  }, [fetchData, getComments]);

  return {
    data,
    isLoading,
    error,
    updateComments,
    updateDocument,
    deleteDocument,
    comments,
    deleteComment,
  };
};

export default useGetDocument;
