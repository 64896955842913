import { db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { CollectionBase } from '@/domain/types/collections';
import { collection, getDocs, query } from 'firebase/firestore';
import { useCallback, useState } from 'react';

const useDashboard = () => {
  const [data, setData] = useState<CollectionBase[]>([]);
  const [totalFavorites, setTotalFavorites] = useState<number>(0);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [totalViews, setTotalViews] = useState<number>(0);
  const [totalDownloads, setTotalDownloads] = useState<number>(0);

  const fetchData = useCallback(async () => {
    try {
      const queries = [
        query(collection(db, DOCUMENTS_DB.NEWSLETTERS)),
        query(collection(db, DOCUMENTS_DB.POLICIES)),
        query(collection(db, DOCUMENTS_DB.REPORTS)),
        query(collection(db, DOCUMENTS_DB.RELEASES)),
        query(collection(db, DOCUMENTS_DB.CRITICAL_STANDARS)),
        query(collection(db, DOCUMENTS_DB.PODCASTS)),
        query(collection(db, DOCUMENTS_DB.VIDEOS)),
      ];

      const queriesSnapshot = await Promise.all(queries.map((q) => getDocs(q)));

      const results = queriesSnapshot.flatMap((snapshot, index) => {
        const collectionName = [
          DOCUMENTS_DB.NEWSLETTERS,
          DOCUMENTS_DB.POLICIES,
          DOCUMENTS_DB.REPORTS,
          DOCUMENTS_DB.RELEASES,
          DOCUMENTS_DB.CRITICAL_STANDARS,
          DOCUMENTS_DB.PODCASTS,
          DOCUMENTS_DB.VIDEOS,
        ][index];

        return snapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as CollectionBase),
          type: collectionName,
          totalFavorites: doc.data().favorites?.length,
          totalComments: doc.data().comments?.length,
        }));
      });

      setTotalFavorites(
        results.reduce((acc, curr) => acc + (curr.totalFavorites || 0), 0),
      );
      setTotalComments(
        results.reduce((acc, curr) => acc + (curr.totalComments || 0), 0),
      );
      setTotalViews(results.reduce((acc, curr) => acc + (curr.views || 0), 0));
      setTotalDownloads(
        results.reduce((acc, curr) => acc + (curr.downloads || 0), 0),
      );

      setData(results);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    data,
    fetchData,
    totalFavorites,
    totalComments,
    totalViews,
    totalDownloads,
  };
};

export default useDashboard;
