const PoliciesPrivacityPage = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <iframe
        src="/privacy_policy.html"
        title="Política de Privacidad"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default PoliciesPrivacityPage;
