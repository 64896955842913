import Button from '@/presentation/components/button';
import { Select } from '@/presentation/components/inputs/select';
import * as ExcelJS from 'exceljs';
import { useState } from 'react';
import { FaChartBar, FaEraser, FaFileExcel } from 'react-icons/fa';

import { COLLECTION_NAME_MAP } from '@/domain/env/collection-name-map';
import { Area, Department, positions, units } from '../env/filters';
import { ReportData } from '../types/intex';

interface FiltersReportProps {
  fetchData: (data: {
    department: string;
    area: string;
    unit: string;
    position: string;
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
  clearData: () => void;
  data: ReportData[];
}

const FiltersReports = ({ fetchData, data, clearData }: FiltersReportProps) => {
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string>('');
  const [departments, setDepartments] = useState<Department[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);

  const validateDates = (start: Date | null, end: Date | null) => {
    if (!start || !end) return true;

    const today = new Date();
    today.setHours(23, 59, 59, 999);

    if (start > end) {
      setDateError('La fecha inicial no puede ser mayor a la fecha final');
      return false;
    }

    if (end > today) {
      setDateError('La fecha final no puede ser mayor a la fecha actual');
      return false;
    }

    setDateError('');
    return true;
  };

  const handleDownload = async () => {
    // crear un archivo excel con los datos de la tabla
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Dashboard');

    // agregar los encabezados
    worksheet.addRow([
      'ID',
      'Título',
      'Tipo',
      'Departamento',
      'Área',
      'Unidad',
      'Posición',
      'Fecha Inicial',
      'Fecha Final',
      'Total de vistas',
    ]);

    // agregar los datos
    data.forEach((item) => {
      worksheet.addRow([
        item.id,
        item.title,
        COLLECTION_NAME_MAP[
          item.collectionName as keyof typeof COLLECTION_NAME_MAP
        ],
        selectedDepartment,
        selectedArea,
        selectedUnit,
        selectedPosition,
        startDate?.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        endDate?.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        item.total,
      ]);
    });

    // descargar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    const url = URL.createObjectURL(
      new Blob([buffer], {
        type: 'application/octet-stream',
      }),
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = 'dashboard.xlsx';
    a.click();

    // eliminar el archivo temporal
    URL.revokeObjectURL(url);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-start items-start gap-2 flex-wrap gap-y-3">
        <Select
          options={units
            .sort((a, b) => a.unit.localeCompare(b.unit))
            .map((unit) => ({
              value: unit.unit,
              label: unit.unit,
            }))}
          onChange={(value) => {
            setSelectedUnit(value);

            setAreas([]);
            setDepartments([]);

            setDepartments(
              units.find((unit) => unit.unit === value)?.departments || [],
            );
          }}
          value={selectedUnit}
          label="Unidad"
          placeholder="Seleccione una unidad"
        />
        <Select
          options={departments
            .sort((a, b) => a.department.localeCompare(b.department))
            .map((department) => ({
              value: department.department,
              label: department.department,
            }))}
          onChange={(value) => {
            setSelectedDepartment(value);
            setAreas([]);
            setAreas(
              departments.find((department) => department.department === value)
                ?.areas || [],
            );
          }}
          value={selectedDepartment}
          label="Departamento"
          placeholder="Seleccione un departamento"
        />
        <Select
          options={areas
            .sort((a, b) => a.area.localeCompare(b.area))
            .map((area) => ({
              value: area.area,
              label: area.area,
            }))}
          onChange={(value) => {
            setSelectedArea(value);
          }}
          value={selectedArea}
          label="Área"
          placeholder="Seleccione una área"
        />

        <Select
          options={positions
            .sort((a, b) => a.localeCompare(b))
            .map((position) => ({
              value: position,
              label: position,
            }))}
          onChange={(value) => {
            setSelectedPosition(value);
          }}
          value={selectedPosition}
          label="Cargo"
          placeholder="Seleccione un cargo"
        />

        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="flex flex-col">
              <label className="text-sm text-gray-700 mb-1">
                Fecha inicial
              </label>
              <input
                type="date"
                className="border rounded-md p-2"
                onChange={(e) => {
                  const date = e.target.value ? new Date(e.target.value) : null;
                  setStartDate(date);
                  validateDates(date, endDate);
                }}
                max={new Date().toISOString().split('T')[0]}
              />
            </div>

            <div className="flex flex-col">
              <label className="text-sm text-gray-700 mb-1">Fecha final</label>
              <input
                type="date"
                className="border rounded-md p-2"
                onChange={(e) => {
                  const date = e.target.value ? new Date(e.target.value) : null;
                  setEndDate(date);
                  validateDates(startDate, date);
                }}
                max={new Date().toISOString().split('T')[0]}
              />
            </div>
          </div>

          {dateError && (
            <span className="text-red-500 text-sm">{dateError}</span>
          )}
        </div>
      </div>

      <div className="flex justify-between items-end gap-2">
        <div className="flex gap-2">
          <Button
            label="Generar reporte"
            icon={<FaChartBar />}
            onClick={() => {
              if (validateDates(startDate, endDate)) {
                fetchData({
                  department: selectedDepartment,
                  area: selectedArea,
                  unit: selectedUnit,
                  position: selectedPosition,
                  startDate,
                  endDate,
                });
              }
            }}
          />

          <Button
            label="Descargar reporte"
            icon={<FaFileExcel />}
            className="bg-green-700 hover:bg-green-800"
            onClick={handleDownload}
            disabled={data.length === 0}
          />
        </div>

        <Button
          label="Limpiar Filtros"
          variant="ghost"
          icon={<FaEraser />}
          onClick={() => {
            setSelectedDepartment('');
            setSelectedArea('');
            setSelectedUnit('');
            setSelectedPosition('');
            setStartDate(null);
            setEndDate(null);
            setDateError('');
            clearData();
          }}
        />
      </div>
    </div>
  );
};

export default FiltersReports;
