import logo from '@/assets/images/logo.png';
import { useState } from 'react';
import { IconType } from 'react-icons';
import { FiX } from 'react-icons/fi';
import {
  MdAnnouncement,
  MdBarChart,
  MdDashboard,
  MdFilterList,
  MdNotifications,
  MdPeople,
  MdPerson,
  MdPodcasts,
  MdPolicy,
  MdReport,
  MdStarRate,
  MdVideoLibrary,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';

interface MenuItem {
  icon: IconType;
  label: string;
  path: string;
  children?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    icon: MdDashboard,
    label: 'Dashboard',
    path: 'home',
  },
  {
    icon: MdNotifications,
    label: 'Boletines y Alertas',
    path: 'newsletters',
  },
  {
    icon: MdPolicy,
    label: 'Políticas',
    path: 'policies',
  },
  {
    icon: MdAnnouncement,
    label: 'Comunicados',
    path: 'releases',
  },
  {
    icon: MdReport,
    label: 'Reportes',
    path: 'reports',
  },
  {
    icon: MdStarRate,
    label: 'Estándares Críticos',
    path: 'critical-standars',
  },
  {
    icon: MdPodcasts,
    label: 'Audios',
    path: 'podcasts',
  },
  {
    icon: MdVideoLibrary,
    label: 'Videos',
    path: 'videos',
  },
  {
    icon: MdPeople,
    label: 'Usuarios',
    path: 'users',
  },
  {
    icon: MdBarChart,
    label: 'Estadísticas',
    path: 'statistics',
    children: [
      {
        icon: MdFilterList,
        label: 'Reporte por filtros',
        path: 'statistics/report-by-filter',
      },
      {
        icon: MdPerson,
        label: 'Reporte por empleado',
        path: 'statistics/report-by-employee',
      },
    ],
  },
];

interface SidebarProps {
  isSidebarOpen: boolean;
  setSidebarOpen: (isSidebarOpen: boolean) => void;
}

const Sidebar = ({ isSidebarOpen, setSidebarOpen }: SidebarProps) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const toggleExpand = (path: string) => {
    setExpandedItems((prev) =>
      prev.includes(path)
        ? prev.filter((item) => item !== path)
        : [...prev, path],
    );
  };

  const renderMenuItem = (item: MenuItem) => {
    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedItems.includes(item.path);

    return (
      <li key={item.path}>
        {hasChildren ? (
          <div>
            <button
              onClick={() => toggleExpand(item.path)}
              className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-700"
            >
              <div className="flex items-center space-x-2">
                <item.icon size={20} />
                <span>{item.label}</span>
              </div>
              <span
                className={`transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
              >
                ▼
              </span>
            </button>
            {isExpanded && (
              <ul className="ml-4 space-y-2 mt-2">
                {item.children?.map((child) => (
                  <li key={child.path}>
                    <NavLink
                      to={child.path}
                      className={({ isActive }) =>
                        `px-4 py-2 flex items-center space-x-2 hover:bg-gray-700 ${
                          isActive ? 'bg-gray-700' : ''
                        }`
                      }
                    >
                      <child.icon size={20} />
                      <span>{child.label}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <NavLink
            to={item.path}
            className={({ isActive }) =>
              `px-4 py-2 flex items-center space-x-2 hover:bg-gray-700 ${
                isActive ? 'bg-gray-700' : ''
              }`
            }
          >
            <item.icon size={20} />
            <span>{item.label}</span>
          </NavLink>
        )}
      </li>
    );
  };

  return (
    <div
      className={`bg-gray-800 text-white w-64 flex-shrink-0 transition-transform duration-300 transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="flex items-center justify-end p-4">
        <button
          onClick={() => setSidebarOpen(false)}
          className="text-gray-400 hover:text-white"
        >
          <FiX size={20} />
        </button>
      </div>
      <div className="flex items-center justify-center my-4">
        <img src={logo} alt="logo" className="w-1/3" />
      </div>

      <nav className="mt-4">
        <ul className="space-y-2">{menuItems.map(renderMenuItem)}</ul>
      </nav>
    </div>
  );
};

export default Sidebar;
