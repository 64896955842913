import { auth, db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { User } from '@/domain/types/user';
import Button from '@/presentation/components/button';
import { InputForm } from '@/presentation/components/inputs/input-form';
import { SelectForm } from '@/presentation/components/inputs/select-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaLock, FaSave, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { z } from 'zod';

interface AddUserFormProps {
  user?: User;
  isEdit?: boolean;
  onSaved?: () => void;
}

const userSchema = z.object({
  document: z
    .string({ message: 'El documento es requerido' })
    .min(7, { message: 'El documento debe tener al menos 7 caracteres' })
    .max(10, { message: 'El documento debe tener máximo 10 caracteres' })
    .regex(/^\d+$/, { message: 'El documento debe contener solo números' }),
  name: z
    .string({ message: 'El nombre es requerido' })
    .min(3, { message: 'El nombre debe tener al menos 3 caracteres' }),
  lastname: z
    .string({ message: 'El apellido es requerido' })
    .min(3, { message: 'El apellido debe tener al menos 3 caracteres' }),
  email: z
    .string({ message: 'El correo electrónico es requerido' })
    .email({ message: 'El correo electrónico no es válido' }),
  department: z
    .string({ message: 'El departamento es requerido' })
    .min(3, { message: 'El departamento debe tener al menos 3 caracteres' }),
  area: z
    .string({ message: 'La área es requerida' })
    .min(3, { message: 'La área debe tener al menos 3 caracteres' }),
  position: z
    .string({ message: 'El cargo es requerido' })
    .min(3, { message: 'El cargo debe tener al menos 3 caracteres' }),
  unit: z.string({ message: 'La unidad es requerida' }),
});

const AddUserForm = ({ user, isEdit, onSaved }: AddUserFormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<User>({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
    defaultValues: { ...user, isEnabled: user?.isEnabled || false },
  });

  useEffect(() => {
    methods.reset({ ...user, isEnabled: user?.isEnabled || false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const saveUser = async (data: User) => {
    setIsLoading(true);
    try {
      const docRef = doc(db, DOCUMENTS_DB.USERS, data.id);
      const isEnabled = methods.getValues('isEnabled').toString();

      const docData = {
        ...data,
        role: 'employee',
        isEnabled: isEnabled === 'true',
      };

      await setDoc(docRef, docData);
      toast.success('Empleado creado correctamente', { theme: 'colored' });
      onSaved?.();
      methods.reset();
    } catch (error) {
      console.error('Error al guardar usuario:', error);
      toast.error('Error al guardar empleado', { theme: 'colored' });
    } finally {
      setIsLoading(false);
    }
  };

  const createUserAuth = async (data: User) => {
    try {
      const collectionRef = collection(db, DOCUMENTS_DB.USERS);
      const queryRef = query(
        collectionRef,
        where('document', '==', data.document),
      );
      const querySnapshot = await getDocs(queryRef);

      if (querySnapshot.size > 0) {
        toast.error('El documento ya está registrado', { theme: 'colored' });
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.document,
      );
      saveUser({ ...data, id: userCredential.user.uid });
    } catch (error) {
      toast.error('Error al crear usuario en auth', { theme: 'colored' });
    }
  };

  const updateUser = async (data: User) => {
    setIsLoading(true);
    try {
      const docRef = doc(db, DOCUMENTS_DB.USERS, data.document);

      const isEnabled = methods.getValues('isEnabled').toString();

      const docData = {
        ...data,
        role: 'employee',
        isEnabled: isEnabled === 'true',
      };

      await updateDoc(docRef, docData);
      toast.success('Empleado actualizado correctamente', { theme: 'colored' });
      onSaved?.();
      methods.reset();
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
      toast.error('Error al actualizar empleado', { theme: 'colored' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!user?.document) return;

    try {
      const docRef = doc(db, DOCUMENTS_DB.USERS, user.id);
      await deleteDoc(docRef);

      toast.success('Empleado eliminado correctamente', { theme: 'colored' });
      onSaved?.();
    } catch (error) {
      console.error('Error al eliminar usuario:', error);
      toast.error('Error al eliminar empleado', { theme: 'colored' });
    }
  };

  const onSubmit = (data: User) => {
    if (isEdit) {
      updateUser(data);
    } else {
      createUserAuth(data);
    }
  };

  const handleResetPassword = async () => {
    try {
      if (user?.email) {
        await sendPasswordResetEmail(auth, user?.email);
        toast.success(
          'Se ha enviado un correo para restablecer la contraseña',
          {
            theme: 'colored',
          },
        );
      } else {
        toast.error('No se puede restablecer la contraseña', {
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error('Error al restablecer contraseña:', error);
      toast.error('Error al restablecer contraseña', { theme: 'colored' });
    }
  };

  return (
    <div className="flex flex-col gap-2 p-4 justify-between h-5/6">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-2">
            <InputForm
              label="Documento"
              name="document"
              placeholder="Documento"
              disabled={isEdit}
            />
            <InputForm label="Nombre" name="name" placeholder="Nombre" />
            <InputForm
              label="Apellido"
              name="lastname"
              placeholder="Apellido"
            />
            <InputForm
              label="Correo electrónico"
              name="email"
              placeholder="Correo electrónico"
              disabled={isEdit}
            />
            <InputForm
              label="Departamento"
              name="department"
              placeholder="Departamento"
            />
            <InputForm label="Área" name="area" placeholder="Área" />
            <InputForm label="Cargo" name="position" placeholder="Cargo" />
            <InputForm label="Unidad" name="unit" placeholder="Unidad" />
            <SelectForm
              label="Estado"
              name="isEnabled"
              options={[
                { value: 'true', label: 'Habilitado' },
                { value: 'false', label: 'Inhabilitado' },
              ]}
            />
            <Button
              icon={<FaSave />}
              label="Guardar"
              type="submit"
              isLoading={isLoading}
            />
          </div>
        </form>
      </FormProvider>

      <div className="flex flex-col gap-2">
        <Button
          label="Restablecer contraseña"
          variant="secondary"
          onClick={handleResetPassword}
          icon={<FaLock />}
        />
        <Button
          label="Eliminar empleado"
          variant="ghost"
          onClick={handleDeleteUser}
          icon={<FaTrash />}
        />
      </div>
    </div>
  );
};

export default AddUserForm;
