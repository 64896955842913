import { db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { CollectionBase } from '@/domain/types/collections';
import FormAddDocument from '@/presentation/components/form-add-document';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ReleaseAddPage = () => {
  const navigate = useNavigate();

  const handleSave = async (data: CollectionBase) => {
    try {
      await addDoc(collection(db, DOCUMENTS_DB.RELEASES), {
        ...data,
        createdAt: serverTimestamp(),
      });
      toast.success('Documento guardado correctamente', {
        theme: 'colored',
      });
      navigate('/dashboard/releases');
    } catch (error) {
      toast.error('Error al guardar el documento', {
        theme: 'colored',
      });
    }
  };

  return (
    <div className="p-4 flex flex-col gap-4 ">
      <h1 className="text-3xl font-bold">Agregar Comunicado</h1>
      <FormAddDocument onSave={handleSave} />
    </div>
  );
};

export default ReleaseAddPage;
