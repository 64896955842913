import { COLLECTION_NAME_MAP } from '@/domain/env/collection-name-map';
import Button from '@/presentation/components/button';
import { Input } from '@/presentation/components/inputs/input';
import * as ExcelJS from 'exceljs';
import { useState } from 'react';
import { FaChartBar, FaEraser, FaFileExcel } from 'react-icons/fa';
import { ReportEmployee } from '../../types';

interface FiltersReportProps {
  fetchData: (data: {
    document: string;
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
  clearData: () => void;
  data: ReportEmployee[];
}

const FiltersReportEmployee = ({
  fetchData,
  data,
  clearData,
}: FiltersReportProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<string>('');
  const [documentEmployee, setDocumentEmployee] = useState<string>('');
  const validateDates = (start: Date | null, end: Date | null) => {
    if (!start || !end) return true;

    const today = new Date();
    today.setHours(23, 59, 59, 999);

    if (start > end) {
      setDateError('La fecha inicial no puede ser mayor a la fecha final');
      return false;
    }

    if (end > today) {
      setDateError('La fecha final no puede ser mayor a la fecha actual');
      return false;
    }

    setDateError('');
    return true;
  };

  const handleDownload = async () => {
    // crear un archivo excel con los datos de la tabla
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Dashboard');

    // agregar los encabezados
    worksheet.addRow([
      'ID',
      'Título',
      'Tipo',
      'Documento',
      'Nombre',
      'Apellido',
      'Departamento',
      'Área',
      'Unidad',
      'Posición',
      'Fecha Inicial',
      'Fecha Final',
      'vistas',
    ]);

    // agregar los datos
    data.forEach((item) => {
      worksheet.addRow([
        item.id,
        item.title,
        COLLECTION_NAME_MAP[
          item.collectionName as keyof typeof COLLECTION_NAME_MAP
        ],
        item.document,
        item.name,
        item.lastname,
        item.department,
        item.area,
        item.unit,
        item.position,
        startDate?.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        endDate?.toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        item.views,
      ]);
    });

    // descargar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    const url = URL.createObjectURL(
      new Blob([buffer], {
        type: 'application/octet-stream',
      }),
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = 'dashboard.xlsx';
    a.click();

    // eliminar el archivo temporal
    URL.revokeObjectURL(url);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-start items-end gap-2 flex-wrap gap-y-3">
        <Input
          placeholder="Documento"
          value={documentEmployee}
          onChange={(e) => {
            setDocumentEmployee(e.target.value);
          }}
        />

        <div className="flex flex-col">
          <label className="text-sm text-gray-700 mb-1">Fecha inicial</label>
          <input
            type="date"
            className="border rounded-md p-2"
            onChange={(e) => {
              const date = e.target.value ? new Date(e.target.value) : null;
              setStartDate(date);
              validateDates(date, endDate);
            }}
            max={new Date().toISOString().split('T')[0]}
            value={startDate?.toISOString().split('T')[0]}
          />
        </div>

        <div className="flex flex-col">
          <label className="text-sm text-gray-700 mb-1">Fecha final</label>
          <input
            type="date"
            className="border rounded-md p-2"
            onChange={(e) => {
              const date = e.target.value ? new Date(e.target.value) : null;
              setEndDate(date);
              validateDates(startDate, date);
            }}
            max={new Date().toISOString().split('T')[0]}
            value={endDate?.toISOString().split('T')[0]}
          />
        </div>

        {dateError && <span className="text-red-500 text-sm">{dateError}</span>}
        <Button
          label="Limpiar Filtros"
          variant="ghost"
          icon={<FaEraser />}
          onClick={() => {
            setDocumentEmployee('');
            setStartDate(null);
            setEndDate(null);
            setDateError('');
            clearData();
          }}
        />
      </div>

      <div className="flex justify-center items-end gap-2">
        <Button
          label="Generar reporte"
          icon={<FaChartBar />}
          onClick={() => {
            if (validateDates(startDate, endDate)) {
              fetchData({
                document: documentEmployee,
                startDate,
                endDate,
              });
            }
          }}
        />

        <Button
          label="Descargar reporte"
          icon={<FaFileExcel />}
          className="bg-green-700 hover:bg-green-800"
          onClick={handleDownload}
          disabled={data.length === 0}
        />
      </div>
    </div>
  );
};

export default FiltersReportEmployee;
