import { CollectionBase } from '@/domain/types/collections';
import uploadFile from '@/domain/use-cases/upload-file';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiSave, FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { z } from 'zod';
import Button from '../button';
import ImageUpload from '../image-upload';
import { Input } from '../inputs/input';
import { InputForm } from '../inputs/input-form';
import { TextAreaForm } from '../inputs/text-area-form';
import PdfUpload from '../pdf-upload';
import AudioUpload from '../audio-upload';
import VideoUpload from '../video-upload';

const documentSchema: z.ZodType<CollectionBase> = z.object({
  title: z.string().min(1, 'El título es requerido'),
  description: z.string().min(1, 'La descripción es requerida'),
  titleEN: z.string().min(1, 'El título en inglés es requerido'),
  descriptionEN: z.string().min(1, 'La descripción en inglés es requerida'),
});

const tagSchema = z.object({
  tag: z.string().min(1, 'El tag es requerido'),
});

interface FormAddDocumentProps {
  onSave: (data: CollectionBase) => void;
  document?: CollectionBase;
  hasAudio?: boolean;
  hasImage?: boolean;
  hasPdf?: boolean;
  hasVideo?: boolean;
}

const FormAddDocument = ({
  onSave,
  document,
  hasAudio = false,
  hasImage = true,
  hasPdf = true,
  hasVideo = false,
}: FormAddDocumentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageEN, setImageEN] = useState<File | null>(null);
  const [pdf, setPdf] = useState<File | null>(null);
  const [pdfEN, setPdfEN] = useState<File | null>(null);
  const [audio, setAudio] = useState<File | null>(null);
  const [audioEN, setAudioEN] = useState<File | null>(null);
  const [video, setVideo] = useState<File | null>(null);
  const [videoEN, setVideoEN] = useState<File | null>(null);

  const [tagInputValue, setTagInputValue] = useState('');
  const [tagInputError, setTagInputError] = useState<string | null>(null);
  const [tagENInputValue, setTagENInputValue] = useState('');
  const [tagENInputError, setTagENInputError] = useState<string | null>(null);

  const [tags, setTags] = useState<{ tag: string }[]>(
    document?.tags?.map((tag) => ({ tag })) || [],
  );
  const [tagsEN, setTagsEN] = useState<{ tag: string }[]>(
    document?.tagsEN?.map((tag) => ({ tag })) || [],
  );

  const methods = useForm<CollectionBase>({
    resolver: zodResolver(documentSchema),
    defaultValues: document,
  });

  const onSubmit = async (data: CollectionBase) => {
    setIsLoading(true);
    try {
      const newDocument: CollectionBase = {
        ...data,
        tags: tags.map((tag) => tag.tag),
        tagsEN: tagsEN.map((tag) => tag.tag),
      };
      if (image) {
        newDocument.image = await uploadFile({ file: image, path: 'images' });
      }
      if (imageEN) {
        newDocument.imageEN = await uploadFile({
          file: imageEN,
          path: 'images',
        });
      }
      if (pdf) {
        newDocument.documentUrl = await uploadFile({ file: pdf, path: 'pdfs' });
      }
      if (pdfEN) {
        newDocument.documentUrlEN = await uploadFile({
          file: pdfEN,
          path: 'pdfs',
        });
      }
      if (audio) {
        newDocument.uri = await uploadFile({ file: audio, path: 'audios' });
      }
      if (audioEN) {
        newDocument.uriEN = await uploadFile({ file: audioEN, path: 'audios' });
      }
      if (video) {
        newDocument.uri = await uploadFile({ file: video, path: 'videos' });
      }
      if (videoEN) {
        newDocument.uriEN = await uploadFile({ file: videoEN, path: 'videos' });
      }

      onSave(newDocument);
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error al subir el documento');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTagInputValue(value);
    setTagInputError(null);
  };

  const handleTagENChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTagENInputValue(value);
    setTagENInputError(null);
  };

  const handleTagKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      try {
        const newTag = { tag: tagInputValue };

        tagSchema.parse(newTag);

        setTags([...tags, newTag]);
        setTagInputError(null);
        setTagInputValue('');
      } catch (error) {
        if (error instanceof z.ZodError) {
          setTagInputError(error.errors[0].message);
        }
      }
    }
  };

  const handleTagENKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      try {
        const newTag = { tag: tagENInputValue };

        tagSchema.parse(newTag);

        setTagsEN([...tagsEN, newTag]);
        setTagENInputError(null);
        setTagENInputValue('');
      } catch (error) {
        if (error instanceof z.ZodError) {
          setTagENInputError(error.errors[0].message);
        }
      }
    }
  };

  const handleRemoveTag = (tag: string) => {
    setTags(tags.filter((t) => t.tag !== tag));
  };

  const handleRemoveTagEN = (tag: string) => {
    setTagsEN(tagsEN.filter((t) => t.tag !== tag));
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 w-full">
      <div className="flex flex-col gap-4 flex-1 bg-white rounded-lg shadow-md p-12">
        {hasImage && (
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-500">Imagen en español</span>
            <ImageUpload
              onFileChange={setImage}
              image={document?.image}
              id="image-upload"
            />
            <span className="text-sm text-gray-500">Imagen en inglés</span>
            <ImageUpload
              onFileChange={setImageEN}
              image={document?.imageEN}
              id="image-upload-en"
            />
          </div>
        )}
        {hasPdf && (
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-500">Documento en español</span>
            <PdfUpload
              onFileChange={setPdf}
              pdf={document?.documentUrl}
              id="pdf-upload"
            />
            <span className="text-sm text-gray-500">Documento en inglés</span>
            <PdfUpload
              onFileChange={setPdfEN}
              pdf={document?.documentUrlEN}
              id="pdf-upload-en"
            />
          </div>
        )}
        {hasAudio && (
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-500">Audio en español</span>
            <AudioUpload
              onFileChange={setAudio}
              audio={document?.uri}
              id="audio-upload"
            />
            <span className="text-sm text-gray-500">Audio en inglés</span>
            <AudioUpload
              onFileChange={setAudioEN}
              audio={document?.uriEN}
              id="audio-upload-en"
            />
          </div>
        )}
        {hasVideo && (
          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-500">Video en español</span>
            <VideoUpload
              onFileChange={setVideo}
              video={document?.uri}
              id="video-upload"
            />
            <span className="text-sm text-gray-500">Video en inglés</span>
            <VideoUpload
              onFileChange={setVideoEN}
              video={document?.uriEN}
              id="video-upload-en"
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-4 flex-1 bg-white rounded-lg shadow-md p-12">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <InputForm name="title" placeholder="Título" />
              <TextAreaForm name="description" placeholder="Descripción" />
              <InputForm name="titleEN" placeholder="Título en inglés" />
              <TextAreaForm
                name="descriptionEN"
                placeholder="Descripción en inglés"
              />

              <div className="flex flex-col gap-2">
                <Input
                  type="text"
                  value={tagInputValue}
                  onChange={handleTagChange}
                  onKeyDown={handleTagKeyDown}
                  placeholder="Agregar tag"
                />
                <span className="text-sm text-gray-500">
                  Presiona Enter para agregar un tag
                </span>
                {tagInputError && (
                  <p className="text-red-500">{tagInputError}</p>
                )}
                <div className="flex flex-wrap gap-2">
                  {tags.map((tag) => (
                    <div
                      key={tag.tag}
                      className="flex items-center justify-between gap-2 bg-gray-500 p-2 rounded-md"
                    >
                      <span className="text-sm text-white">{tag.tag}</span>
                      <button
                        className="text-sm text-white"
                        onClick={() => handleRemoveTag(tag.tag)}
                      >
                        <FiX />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Input
                  type="text"
                  value={tagENInputValue}
                  onChange={handleTagENChange}
                  onKeyDown={handleTagENKeyDown}
                  placeholder="Agregar tag en inglés"
                />
                <span className="text-sm text-gray-500">
                  Presiona Enter para agregar un tag en inglés
                </span>
                {tagENInputError && (
                  <p className="text-red-500">{tagENInputError}</p>
                )}
                <div className="flex flex-wrap gap-2">
                  {tagsEN.map((tag) => (
                    <div
                      key={tag.tag}
                      className="flex items-center justify-between gap-2 bg-gray-500 p-2 rounded-md"
                    >
                      <span className="text-sm text-white">{tag.tag}</span>
                      <button
                        className="text-sm text-white"
                        onClick={() => handleRemoveTagEN(tag.tag)}
                      >
                        <FiX />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-center mt-3">
                <Button
                  label="Guardar"
                  type="submit"
                  isLoading={isLoading}
                  onClick={() => methods.handleSubmit(onSubmit)}
                  icon={<FiSave />}
                  className="w-[200px]"
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default FormAddDocument;
