import { CommentDocument } from '@/domain/types/collections';
import { FiTrash } from 'react-icons/fi';
import Button from '../../button';

const CommentsDetailDocument = ({
  data,
  deleteComment,
}: {
  data: CommentDocument[];
  deleteComment: (id: string) => void;
}) => {
  return (
    <div className="bg-white rounded-lg p-4 shadow-md flex flex-1 flex-col gap-4">
      <div className="flex flex-col gap-4">
        <span className="text-sm text-gray-500">Comentarios</span>
        <div className="flex flex-col gap-4">
          {data?.map((comment) => (
            <div
              key={comment.id}
              className="flex flex-col gap-2 border-b border-gray-200 pb-4"
            >
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col gap-2">
                  <span className="text-sm font-bold">{comment.name}</span>
                  <span className="text-sm text-gray-500">
                    {comment.comment}
                  </span>
                </div>
                <Button
                  variant="ghost"
                  label=""
                  onClick={() => deleteComment(comment.id)}
                  icon={<FiTrash className="w-4 h-4" />}
                  className="w-fit"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommentsDetailDocument;
