import { CollectionBase } from '@/domain/types/collections';

const StatsDetailDocument = ({ data }: { data: CollectionBase }) => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="bg-white rounded-lg p-4 shadow-md flex flex-1 flex-col gap-4 justify-center items-center">
        <span className="text-sm text-gray-500">Descargas</span>
        <span className="text-2xl font-bold">{data.downloads || 0}</span>
      </div>
      <div className="bg-white rounded-lg p-4 shadow-md flex flex-1 flex-col gap-4 justify-center items-center">
        <span className="text-sm text-gray-500">Vistas</span>
        <span className="text-2xl font-bold">{data.views || 0}</span>
      </div>
      {data.createdAt?.seconds && (
        <div className="bg-white rounded-lg p-4 shadow-md flex flex-1 flex-col gap-4 justify-center items-center">
          <span className="text-sm text-gray-500">Fecha de creación</span>
          <span className="text-2xl font-bold">
            {new Date(data.createdAt.seconds * 1000).toLocaleDateString()}
          </span>
        </div>
      )}
      <div className="bg-white rounded-lg p-4 shadow-md flex flex-1 flex-col gap-4 justify-center items-center">
        <span className="text-sm text-gray-500">Favoritos</span>
        <span className="text-2xl font-bold">{data.favorites || 0}</span>
      </div>
      <div className="bg-white rounded-lg p-4 shadow-md flex flex-1 flex-col gap-4 justify-center items-center">
        <span className="text-sm text-gray-500">Comentarios</span>
        <span className="text-2xl font-bold">{data.comments || 0}</span>
      </div>
    </div>
  );
};

export default StatsDetailDocument;
