import { storage } from '@/data/firebase/config';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';

type UploadFileProps = {
  file: File;
  path: string;
};

const uploadFile = async ({ file, path }: UploadFileProps) => {
  try {
    const fileExtension = file.name.split('.').pop();
    const uniqueId = Date.now().toString();
    const newFileName = `${uniqueId}.${fileExtension}`;
    const storageRef = ref(storage, `${path}/${newFileName}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export default uploadFile;
