export type Subarea = {
  subarea: string;
};

export type Area = {
  area: string;
  subareas?: Subarea[];
};

export type Department = {
  department: string;
  areas: Area[];
};

export type Unit = {
  unit: string;
  departments: Department[];
};

export const units: Unit[] = [
  {
    unit: 'BOGOT',
    departments: [
      {
        department: 'Administration',
        areas: [
          {
            area: 'Accounting',
          },
          {
            area: 'Administration',
          },
          {
            area: 'Audit',
          },
          {
            area: 'Engineering',
          },
          {
            area: 'Human Resources',
            subareas: [
              {
                subarea: 'HR Admin & Benefits',
              },
            ],
          },
          {
            area: 'Land',
          },
          {
            area: 'Legal',
          },
          {
            area: 'MIS',
          },
          {
            area: 'Public Affairs & Communication',
          },
          {
            area: 'Security',
          },
          {
            area: 'Taxes',
          },
          {
            area: 'Treasury',
          },
        ],
      },
      {
        department: 'Drummond Energy Inc',
        areas: [
          {
            area: 'Corporate and Commercial',
            subareas: [
              {
                subarea: 'Corporate and Commercial',
              },
            ],
          },
          {
            area: 'Country Manager',
          },
          {
            area: 'Exploration',
            subareas: [
              {
                subarea: 'Geology',
              },
              {
                subarea: 'Wells & Reservoirs Engineering',
              },
            ],
          },
          {
            area: 'Facilities Engineering & Const',
            subareas: [
              {
                subarea: 'Facilities Engineering & Const',
              },
            ],
          },
          {
            area: 'Operations',
            subareas: [
              {
                subarea: 'Operations',
              },
            ],
          },
          {
            area: 'Planning and Development',
            subareas: [
              {
                subarea: 'Administration And Finances',
              },
              {
                subarea: 'Contractual Compliance',
              },
              {
                subarea: 'HSEQ & Communities',
              },
              {
                subarea: 'Project Management Office',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    unit: 'CARTA',
    departments: [
      {
        department: 'Administration',
        areas: [
          {
            area: 'Administration',
            subareas: [
              {
                subarea: 'Cartagena',
              },
            ],
          },
          {
            area: 'Security',
            subareas: [
              {
                subarea: 'Cartagena',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    unit: 'ELCOR',
    departments: [
      {
        department: 'Administration',
        areas: [
          {
            area: 'Environmental',
          },
        ],
      },
      {
        department: 'Production',
        areas: [
          {
            area: 'Dumps',
          },
          {
            area: 'Roads',
          },
          {
            area: 'Shovels',
          },
          {
            area: 'Trucks',
          },
        ],
      },
      {
        department: 'Transitional Worker',
        areas: [
          {
            area: 'Transitional Worker',
          },
        ],
      },
    ],
  },
  {
    unit: 'ELDES',
    departments: [
      {
        department: 'Administration',
        areas: [
          {
            area: 'Engineering',
          },
          {
            area: 'Environmental',
          },
          {
            area: 'Human Resources',
            subareas: [
              {
                subarea: 'HR Admin & Benefits',
              },
              {
                subarea: 'Human Resources',
              },
            ],
          },
          {
            area: 'MIS',
          },
          {
            area: 'Occupational Health',
            subareas: [
              {
                subarea: 'Medical Division',
              },
            ],
          },
          {
            area: 'Payroll',
          },
          {
            area: 'Public Affairs & Communication',
          },
          {
            area: 'Safety',
          },
          {
            area: 'Security',
          },
          {
            area: 'Support Services',
          },
        ],
      },
      {
        department: 'Coal',
        areas: [
          {
            area: 'Coal',
          },
        ],
      },
      {
        department: 'Load Out',
        areas: [
          {
            area: 'Load Out',
          },
        ],
      },
      {
        department: 'Maintenance',
        areas: [
          {
            area: 'Bucyrus',
          },
          {
            area: 'Draglines',
          },
          {
            area: 'Drills & Pumps',
          },
          {
            area: 'Electrical',
            subareas: [
              {
                subarea: 'Control & Instrumentation',
              },
              {
                subarea: 'High Voltage',
              },
              {
                subarea: 'Planning',
              },
            ],
          },
          {
            area: 'Fixed Equipment',
            subareas: [
              {
                subarea: 'Apron Feeder',
              },
            ],
          },
          {
            area: 'Fuel & Lube',
          },
          {
            area: 'Planning',
            subareas: [
              {
                subarea: 'Fixed Equipment',
              },
              {
                subarea: 'Mobile Equipment',
              },
            ],
          },
          {
            area: 'Rubber Tire & Tractor Fleet',
          },
          {
            area: 'Shovels',
          },
          {
            area: 'Small Vehicle',
          },
          {
            area: 'Truck Fleet',
            subareas: [
              {
                subarea: 'Coal & Aux Trucks',
              },
            ],
          },
          {
            area: 'Welding',
          },
        ],
      },
      {
        department: 'Materials',
        areas: [
          {
            area: 'Warehouse',
          },
        ],
      },
      {
        department: 'Production',
        areas: [
          {
            area: 'Dispatch',
          },
          {
            area: 'Dragline',
            subareas: [
              {
                subarea: 'Dragline 1570',
              },
              {
                subarea: 'Dragline Marion 8750',
              },
            ],
          },
          {
            area: 'Drill & Blasting',
          },
          {
            area: 'Dumps',
          },
          {
            area: 'Engineering',
          },
          {
            area: 'High Wall Mining El Descanso',
          },
          {
            area: 'Pumps',
          },
          {
            area: 'Roads',
          },
          {
            area: 'Shovels',
          },
          {
            area: 'Training',
          },
          {
            area: 'Trucks',
          },
        ],
      },
      {
        department: 'Transitional Worker',
        areas: [
          {
            area: 'Transitional Worker',
          },
        ],
      },
    ],
  },
  {
    unit: 'PRIBB',
    departments: [
      {
        department: 'Administration',
        areas: [
          {
            area: 'Administration',
          },
          {
            area: 'Aviation',
          },
          {
            area: 'Communities',
          },
          {
            area: 'Construction',
          },
          {
            area: 'Corporate Social Respon',
          },
          {
            area: 'Costs',
          },
          {
            area: 'Engineering',
          },
          {
            area: 'Environmental',
          },
          {
            area: 'Human Resources',
            subareas: [
              {
                subarea: 'Communications',
              },
              {
                subarea: 'Human Resources',
              },
              {
                subarea: 'Labor Relations',
              },
              {
                subarea: 'Selection & Development',
              },
            ],
          },
          {
            area: 'Land',
          },
          {
            area: 'Legal',
          },
          {
            area: 'MIS',
          },
          {
            area: 'Occupational Health',
            subareas: [
              {
                subarea: 'Industrial Hygiene',
              },
              {
                subarea: 'Medical Division',
              },
              {
                subarea: 'Occupational Health',
              },
            ],
          },
          {
            area: 'Payroll',
          },
          {
            area: 'Public Affairs & Communication',
          },
          {
            area: 'Safety',
          },
          {
            area: 'Security',
          },
          {
            area: 'Support Services',
          },
        ],
      },
      {
        department: 'Coal',
        areas: [
          {
            area: 'Coal',
          },
        ],
      },
      {
        department: 'Load Out',
        areas: [
          {
            area: 'Coal Quality',
          },
          {
            area: 'Load Out',
          },
          {
            area: 'Prep Plant',
          },
        ],
      },
      {
        department: 'Maintenance',
        areas: [
          {
            area: 'Dragline',
          },
          {
            area: 'Drills',
          },
          {
            area: 'Electrical',
            subareas: [
              {
                subarea: 'Control & Instrumentation',
              },
              {
                subarea: 'High Voltage',
              },
              {
                subarea: 'Ing & Construction',
              },
              {
                subarea: 'Planning',
              },
            ],
          },
          {
            area: 'Fixed Equipment',
            subareas: [
              {
                subarea: 'Apron Feeder',
              },
            ],
          },
          {
            area: 'Fuel & Lube',
          },
          {
            area: 'Maintenance Center',
          },
          {
            area: 'Planning',
            subareas: [
              {
                subarea: 'Fixed Equipment',
              },
              {
                subarea: 'Mobile Equipment',
              },
            ],
          },
          {
            area: 'Pumps',
          },
          {
            area: 'Rubber Tire Fleet',
          },
          {
            area: 'Shovel',
          },
          {
            area: 'Small Vehicle',
          },
          {
            area: 'Tire Training & Mtt Plant',
          },
          {
            area: 'Truck Fleet',
            subareas: [
              {
                subarea: 'Coal & Aux Trucks',
              },
              {
                subarea: 'Trucks 793',
              },
            ],
          },
          {
            area: 'Welding',
          },
        ],
      },
      {
        department: 'Production',
        areas: [
          {
            area: 'Dispatch',
          },
          {
            area: 'Dragline',
            subareas: [
              {
                subarea: 'Apron Feeder 2570',
              },
              {
                subarea: 'Dragline 2570',
              },
            ],
          },
          {
            area: 'Drill & Blasting',
          },
          {
            area: 'Dumps',
          },
          {
            area: 'Engineering',
          },
          {
            area: 'Pumps',
          },
          {
            area: 'Roads',
          },
          {
            area: 'Shovels',
          },
          {
            area: 'Training',
          },
          {
            area: 'Trucks',
          },
        ],
      },
      {
        department: 'Transitional Worker',
        areas: [
          {
            area: 'Transitional Worker',
          },
        ],
      },
      {
        department: 'Drummond Energy Inc',
        areas: [
          {
            area: 'Facilities Engineering & Const',
            subareas: [
              {
                subarea: 'Facilities Engineering & Const',
              },
            ],
          },
          {
            area: 'Operations',
            subareas: [
              {
                subarea: 'Maintenance',
              },
              {
                subarea: 'Operations',
              },
              {
                subarea: 'Production',
              },
            ],
          },
          {
            area: 'Planning and Development',
            subareas: [
              {
                subarea: 'Administration And Finances',
              },
              {
                subarea: 'HSEQ & Communities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    unit: 'PORT',
    departments: [
      {
        department: 'Administration',
        areas: [
          {
            area: 'Accounting',
            subareas: [
              {
                subarea: 'Fixed Assets',
              },
              {
                subarea: 'Inventory',
              },
              {
                subarea: 'Payable',
              },
              {
                subarea: 'Payroll',
              },
              {
                subarea: 'Special Project',
              },
            ],
          },
          {
            area: 'Administration',
            subareas: [
              {
                subarea: 'Community',
              },
            ],
          },
          {
            area: 'Audit',
          },
          {
            area: 'Aviation',
          },
          {
            area: 'Construction',
          },
          {
            area: 'Costs',
          },
          {
            area: 'Engineering',
          },
          {
            area: 'Environmental',
          },
          {
            area: 'Human Resources',
            subareas: [
              {
                subarea: 'HR Admin & Benefits',
              },
              {
                subarea: 'Human Resources',
              },
              {
                subarea: 'Labor Relations',
              },
              {
                subarea: 'Selection & Development',
              },
            ],
          },
          {
            area: 'Legal',
          },
          {
            area: 'MIS',
          },
          {
            area: 'Occupational Health',
            subareas: [
              {
                subarea: 'Industrial Hygiene',
              },
              {
                subarea: 'Medical Division',
              },
            ],
          },
          {
            area: 'Public Affairs & Communication',
          },
          {
            area: 'Safety',
          },
          {
            area: 'Security',
            subareas: [
              {
                subarea: 'Port Facility',
              },
              {
                subarea: 'Railroad & Logistics',
              },
            ],
          },
          {
            area: 'Support Services',
          },
          {
            area: 'Training',
          },
          {
            area: 'Treasury',
          },
        ],
      },
      {
        department: 'Materials',
        areas: [
          {
            area: 'Contracts',
          },
          {
            area: 'Imports',
          },
          {
            area: 'Logistics',
          },
          {
            area: 'Purchasing',
          },
          {
            area: 'Warehouse',
            subareas: [
              {
                subarea: 'Inventory',
              },
            ],
          },
        ],
      },
      {
        department: 'Railroad',
        areas: [
          {
            area: 'Maintenance',
          },
          {
            area: 'Operations',
            subareas: [
              {
                subarea: 'Track Maintenance',
              },
            ],
          },
        ],
      },
      {
        department: 'Yard',
        areas: [
          {
            area: 'Maintenance',
            subareas: [
              {
                subarea: 'Auxiliary Services',
              },
              {
                subarea: 'Cardumpers & Conveyors',
              },
              {
                subarea: 'Electricians',
              },
              {
                subarea: 'Maintenance Planner',
              },
              {
                subarea: 'Mobile Equipment',
              },
              {
                subarea: 'ShipLoader & Stacker',
              },
            ],
          },
          {
            area: 'Operations',
            subareas: [
              {
                subarea: 'Loading Control Room',
              },
              {
                subarea: 'Pier',
              },
              {
                subarea: 'Workboats',
              },
              {
                subarea: 'Yard',
              },
            ],
          },
        ],
      },
      {
        department: 'Transitional Worker',
        areas: [
          {
            area: 'Transitional Worker',
          },
        ],
      },
    ],
  },
  {
    unit: 'VALLE',
    departments: [
      {
        department: 'Drummond Energy Inc',
        areas: [
          {
            area: 'Corporate and Commercial',
            subareas: [
              {
                subarea: 'Corporate and Commercial',
              },
            ],
          },
          {
            area: 'Exploration',
            subareas: [
              {
                subarea: 'Geology',
              },
            ],
          },
          {
            area: 'Operations',
            subareas: [
              {
                subarea: 'Operations',
              },
              {
                subarea: 'Production',
              },
            ],
          },
          {
            area: 'Planning and Development',
            subareas: [
              {
                subarea: 'Administration And Finances',
              },
              {
                subarea: 'HSEQ & Communities',
              },
            ],
          },
        ],
      },
      {
        department: 'Administration',
        areas: [
          {
            area: 'Communities',
          },
          {
            area: 'Human Resources',
            subareas: [
              {
                subarea: 'HR Admin & Benefits',
              },
            ],
          },
          {
            area: 'Legal',
          },
          {
            area: 'Occupational Health',
            subareas: [
              {
                subarea: 'Medical Division',
              },
            ],
          },
        ],
      },
    ],
  },
];

//   'Maintenance',
//   'Administration',
//   'Production',
//   'Drummond Energy Inc',
//   'Yard',
//   'Coal',
//   'Railroad',
//   'Transitional Worker',
//   'Materials',
//   'Load Out',
//   'Produccion',
//   'Producción',
// ];

// export const areas = [
//   'Dragline',
//   'Maintenance Center',
//   'Railroad',
//   'Contabilidad',
//   'Production',
//   'Dragaline',
//   'Load Out',
//   'Welding',
//   'Operations',
//   'Coal Quality',
//   'HWM - High Wall',
//   'Coal',
//   'Fixed Equipment',
//   'Maintenance',
//   'Electrical',
//   'Shovels',
//   'Drill & Blasting',
//   'Rubber Tire & Tractor Fleet',
//   'Accounting',
//   'Training',
//   'Security',
//   'Bucyrus',
//   'MIS',
//   'Communities',
//   'Shovel',
//   'Dumps',
//   'Land',
//   'Drills',
//   'Construction',
//   'Exploration',
//   'Transitional Worker',
//   'Warehouse',
//   'Engineering',
//   'Dispatch',
//   'Roads',
//   'Trucks',
//   'Logistics',
//   'Truck Fleet',
//   'Aviation',
//   'Environmental',
//   'Support Services',
//   'High Wall Mining El Descanso',
//   'Fuel & Lube',
//   'Small Vehicle',
//   'Administration',
//   'Pumps',
//   'Safety',
//   'Legal',
//   'Planning and Development',
//   'Occupational Health',
//   'Facilities Engineering & Const',
//   'Corporate Social Respon',
//   'Drills & Pumps',
//   'Planning',
//   'Imports',
//   'Purchasing',
//   'Audit',
//   'Public Affairs & Communication',
//   'Costs',
//   'Treasury',
//   'Corporate and Commercial',
//   'Human Resources',
//   'Taxes',
//   'Country Manager',
//   'Calidad de Carbon',
//   'Carbón',
//   'Tire Training & Mtt Plant',
//   'Contracts',
// ];

export const positions = [
  'Maintenance Superintendent – Draglines and Shovels 495',
  'Senior Maintenance Supervisor',
  'Train Operations Manager',
  'Administrative and Financial Vice President',
  'Dragline Superintendent',
  'Vice President of Mining Operations',
  'Vice President of Maintenance',
  'Dragline Maintenance Superintendent',
  'Load Out Superintendent',
  'Assistant Superintendent of Welding Maintenance',
  'Superintendent of New Maintenance Center',
  'General Superintendent',
  'Maintenance Lead Supervisor',
  'Vice President of Coal Quality',
  'Electrical Supervisor Mining Operations High Wall',
  'Senior Supervisor Dragline Operations',
  'Assistant Superintendent of High Wall Mining',
  'Senior Port Maintenance Manager',
  'Operations Support Manager',
  'Mobile Equipment Maintenance Superintendent',
  'Vice President of Transportation',
  'Assistant Superintendent of Coal Pribbenow Mine',
  'Fixed Equipment Maintenance Manager',
  'Electrical Department Manager DLTD',
  'Production Supervisor',
  'Asst Supte Auxiliary Services',
  'Maintenance Senior Supervisor',
  'Payroll Coordinator',
  'Training Supervisor',
  'Coal Supervisor',
  'Vicep of Security',
  'Operations Lead Supervisor',
  'Supervisor Senior Mantenimiento Shovels',
  'Communications Coordinator',
  'Operations Supervisor',
  'Commu and Government Relat VP',
  'Maintenance Supervisor',
  'Production Senior Supervisor',
  'Coal Senior Supervisor',
  'Electr Detonation Assist Sup',
  'Land Assistant Supervisor',
  'Security Supervisor',
  'Construction Asst. Supte.',
  'Security Senior Supervisor',
  'Geology Director',
  'Gas Projects Supervisor',
  'Warehouse Supervisor',
  'Track Maintenance Supervisor',
  'Senior Production Engineer',
  'Dispatch Supervisor',
  'Laboratory Supervisor',
  'Fuel Supervisor',
  'Logistics & Transp. Sup.',
  'Warehouse Senior Supervisor',
  'Maintenance Superintendent',
  'Production Superintendent',
  'Wireline Senior Supervisor',
  'Assistant Superintendent',
  'Maintenance Coordinator',
  'Biodiversity & Compens Sr Sup',
  'Lead Dragline Supervisor',
  'Training Senior Coordinator',
  'Camp Manager',
  'Superintendent',
  'Network & Infrae Coordinator',
  'Maintenance Assistant Superint',
  'HWM Senior Supervisor',
  'Senior Mine Engineer',
  'Operations Senior Supervisor',
  'Production Lead Supervisor',
  'Senior Supervisor',
  'Evironmental Leader Supervisor',
  'Camp Supervisor',
  'Senior Operations Supervisor',
  'Community Relat Senior Supervi',
  'Safety Supervisor',
  'Biodiversity Senior Supervisor',
  'Communications Supervisor',
  'Resettlement Senior Supervisor',
  'Env Monitoring Snr Supervisor',
  'Juridical Director',
  'Logistic Director',
  'Occupational Health Manager',
  'Automation Snr Supervisor',
  'Corp Social Resp Supervisor',
  'Security Director',
  'Survey Supervisor',
  'Load Out Supervisor',
  'Specialized Training Snr Coord',
  'Security Coordinator',
  'Senior Planner',
  'Load Out Senior Supervisor',
  'Safety Director',
  'Medical Coordinator',
  'Tariff Clasification Superviso',
  'Vicepresident of Hydrocarbons',
  'VP of Materials Department',
  'Facilities Assistant Supte.',
  'Environmental Compensation Dir',
  'Safety Senior Supervisor',
  'Aviation Supervisor',
  'Warranties Supervisor',
  'Support Services Director',
  'Corporate Legal Vicepresident',
  'Administrative Director',
  'Payroll Senior Supervisor',
  'Accounting Director',
  'Construction Senior Analyst',
  'Accounting Supervisor',
  'Supplier Supervisor',
  'Environm Permits Senior Superv',
  'HSE & Communities Manager',
  'Finance & Admistrative Manager',
  'Manager',
  'Government Affairs Manager',
  'Planning and Monitoring Sup',
  'Import Supervisor',
  'Assistant Supervisor',
  'Communications Director',
  'Cost Supervisor',
  'Treasury Supervisor',
  'Planner Coordinator',
  'Administrative Manager',
  'Land Manager',
  'Senior Accounting Analyst',
  'Imports Manager',
  'Camp Senior Supervisor',
  'Senior Peoplesoft Admin',
  'Corporate and Commercial Direc',
  'Import Director',
  'Aviation Senior Supervisor',
  'Training/Development Director',
  'Presidency assistant',
  'Compliance Systems Coordinator',
  'Administrative Coordinator',
  'Risk Management Supervisor',
  'Tax Special Projects Director',
  'Environmental Legal Director',
  'Commercial Manager',
  'Proy and Procedu Coordinator',
  'Human Resources Director',
  'Administrative Supervisor',
  'Operations Coordinator',
  'Purchasing Director',
  'Manager of Special Projects',
  'Exploration Manager',
  'Logistic Manager',
  'Inventory Manager',
  'MIS Manager',
  'Wireless Network Supervisor',
  'Supplier Setup Supervisor',
  'Operations Assistant Superint',
  'Projects Senior Supervisor',
  'Accounting Senior Supervisor',
  'Electronic Security Director',
  'Manager of Mine Engineering',
  'Medical Director',
  'Superintendent Of Operations',
  'Construction Manager',
  'Aviation Manager',
  'Maintenance Superintendent Ele',
  'Lead Supervisor',
  'Environmental Rehab Supervisor',
  'Environmental Reclam. Snr Sup.',
  'Community Relations Coordinato',
  'Coal Lead Supervisor',
  'Railroad Maint Senior Supervis',
  'Human Resources Vicepresident',
  'Dispatch Senior Supervisor',
  'Survey Senior Supervisor',
  'Training Director',
  'Labor Relations Manager',
  'Planning FXE Superintendent',
  'Help Desk Senior Supervisor',
  'Technical Supervisor',
  'Safety Manager',
  'Director Of Cost Accounting',
  'Camp Assistant Supervisor',
  'Environmental Mngmt Supervisor',
  'Human Resources Manager',
  'Cost Senior Supervisor',
  'HR Senior Consultant',
  'President',
  'Vicep Of Public Affairs & Comm',
  'Camp Director',
  'Training and Desing Supervisor',
  'Economic Studies Manager',
  'Licensing & Permits Manager',
  'Security Superintendent',
  'Inventory Director',
  'Facilities Engineering Manager',
  'Tax Manager Compliance',
  'Vicepresident Senior of Taxes',
  'Manager of Production Engineer',
  'Vice President CSR & Resettlem',
  'Vicepresident of Energy',
  'Senior Geologist of Operations',
  'HR Admin Manager',
  'Senior Internal Audit',
  'Ops Support Lead Supervisor',
  'Maintenance Assistant Supte.',
  'Environm Monit Lead Supervisor',
  'Industrial Hygiene Supervisor',
  'Labor Relations Superintendent',
  'Financial Reports Director',
  'Legal Manager',
  'Environmental Manager',
  'Country Manager',
  'Purchasing Manager',
  'Explosives Plant Snr Superviso',
  'Tax Director in Consul and Lit',
  'Planning ME Superintendent',
  'Training Senior Supervisor',
  'Supervisor',
  'Corporate Affairs Manager',
  'Construction Lead Supervisor',
  'Supte. Auxiliary Services',
  'Dispatch Lead Supervisor',
  'Operations Manager',
  'Superintendent ME Maintenance',
  'Environm Mngmt Lead Supervisor',
  'Vicep Structural/ Mechanical E',
  'Superintendente Calidad de Carbón',
  'Superintendente Dept Electrico',
  'Superintendente de Carbón',
  'Financial Supervisor',
  'Corpor and Comm Affairs Superv',
  'PMO Supervisor',
  'Commercial Affairs Senr Superv',
  'GIS Administration Supervisor',
  'Compensation & Benefits Sup',
  'Reservoir Engineering Director',
  'Environmental Compensation Sup',
  'Corp. Social Resp. Coordinator',
  'Network and Infrastr Sr Superv',
  'Environmental Permits Superv',
  'EMP Social Coordinator',
  'Human Resources Coordinator',
  'Environmental Supervisor',
  'Social Supervisor',
  'Payroll Supervisor',
  'Reporting and Monitoring Coord',
  'Tax Planning Director',
  'Inventory Coordinator',
  'Resettlement Coordinator',
  'Environmental Management Coord',
  'Supplier Setup Assistant',
  'Export Supervisor',
  'Land Coordinator',
  'O&M Prd Facilities Snr Superv',
  'Contracts Director',
  'Corp Social Resp Project Coord',
  'Corporate and Commercial Coord',
  'Training/Development Assistant',
  'Superintendente Asistente de Carbon Mina Descanso',
  'Supervisor Senior de Mantenimiento - Centro de mantenimiento',
  'vicepresidente de servicio tecnico',
];
