import React, { useState } from 'react';
import { FaMusic } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface AudioUploadProps {
  onFileChange: (file: File | null) => void;
  audio?: string | null;
  id: string;
}

const AudioUpload: React.FC<AudioUploadProps> = ({
  onFileChange,
  audio,
  id,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log(file);
    if (file && file.type === 'audio/mpeg') {
      setSelectedFile(file);
      onFileChange(file);
    } else {
      alert('Por favor, selecciona un archivo MP3.');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files?.[0];
    if (file && file.type === 'audio/mpeg') {
      setSelectedFile(file);
      onFileChange(file);
    } else {
      alert('Por favor, arrastra un archivo MP3.');
    }
  };

  const RenderContent = () => {
    if (selectedFile) {
      return (
        <>
          <FaMusic size={60} className="text-red-500" />
          <div className="text-center">
            <p className="text-lg font-semibold text-gray-700">
              {selectedFile.name}
            </p>
          </div>
        </>
      );
    }
    if (audio) {
      return (
        <>
          <FaMusic size={60} className="text-red-500" />
          <div className="text-center">
            <Link to={audio} target="_blank" className="text-blue-500">
              Escuchar audio
            </Link>
            <p className="text-sm text-gray-500">
              Haz clic para cambiar el audio
            </p>
          </div>
        </>
      );
    }
    return (
      <>
        <FaMusic size={60} className="text-gray-500" />
        <div className="text-center">
          <p className="text-lg font-semibold text-gray-700">
            Arrastra y suelta tu audio aquí
          </p>
          <p className="text-sm text-gray-500">o haz clic para seleccionar</p>
        </div>
      </>
    );
  };

  return (
    <div
      className="flex flex-col items-center space-y-4"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 w-full bg-white hover:border-blue-500 transition-colors">
        <input
          type="file"
          accept="audio/mpeg"
          onChange={handleFileChange}
          className="hidden"
          id={id}
        />
        <label
          htmlFor={id}
          className="flex flex-col items-center space-y-4 cursor-pointer"
        >
          <RenderContent />
        </label>
      </div>
    </div>
  );
};

export default AudioUpload;
