import { auth, db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import Button from '@/presentation/components/button';
import { InputForm } from '@/presentation/components/inputs/input-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import logo from '@/assets/images/logo.png';
type LoginFormData = {
  email: string;
  password: string;
};

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters long'),
});

const Login: React.FC = () => {
  const methods = useForm<LoginFormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (data: LoginFormData) => {
    try {
      // Authenticate the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      const user = userCredential.user;

      // Check the role in Firestore
      const userDoc = await getDoc(doc(db, DOCUMENTS_DB.USERS_ADMIN, user.uid));

      if (userDoc.exists() && userDoc.data().role === 'administrator') {
        alert('Welcome, Administrator!');
        // Redirect or handle successful login
      } else {
        alert('Access denied: Not an administrator.');
        // Handle unauthorized access
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Login failed: ' + (error as Error).message);
    }
  };

  return (
    <div className="min-h-screen flex flex-col gap-8 items-center justify-center bg-gradient-to-br from-primary to-gray-600 p-4">
      <div className="flex items-center justify-center">
        <img src={logo} alt="logo" className="w-1/3" />
      </div>

      <div className="w-full max-w-md bg-white/90 backdrop-blur-lg rounded-2xl shadow-2xl p-8 space-y-6">
        <div className="text-center space-y-2">
          <h2 className="text-3xl font-bold text-gray-800">Bienvenido</h2>
          <p className="text-gray-500">Ingresa a tu cuenta</p>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
            <div className="space-y-4">
              <InputForm
                name="email"
                placeholder="correo@ejemplo.com"
                type="email"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 transition-all"
              />
              <InputForm
                name="password"
                placeholder="Contraseña"
                type="password"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-20 transition-all"
              />
            </div>

            <Button
              label="Iniciar Sesión"
              onClick={methods.handleSubmit(onSubmit)}
              variant="primary"
              className="w-full py-3  text-white rounded-lg font-medium shadow-lg hover:shadow-xl transition-all duration-200"
              type="submit"
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
