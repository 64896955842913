interface ProgressBarProps {
  progress: number;
  total: number;
  className?: string;
}

const ProgressBar = ({ progress, total, className = '' }: ProgressBarProps) => {
  const percentage = Math.round((progress / total) * 100);

  return (
    <div className={`w-full ${className}`}>
      <div className="w-full h-4 bg-gray-200 rounded-full">
        <div
          className="h-full bg-primary rounded-full transition-all duration-300 ease-in-out"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <span className="text-sm text-gray-600 mt-1">
        {progress} de {total} ({percentage}%)
      </span>
    </div>
  );
};

export default ProgressBar;
