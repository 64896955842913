import React, { useState } from 'react';
import { MdImage } from 'react-icons/md';

interface ImageUploadProps {
  onFileChange: (file: File | null) => void;
  id: string;
  image?: string | null;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onFileChange,
  image,
  id,
}) => {
  const [previewURL, setPreviewURL] = useState<string | null>(image || null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setPreviewURL(URL.createObjectURL(file));
      onFileChange(file);
    } else {
      alert('Por favor, selecciona una imagen.');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setPreviewURL(URL.createObjectURL(file));
      onFileChange(file);
    } else {
      alert('Por favor, arrastra una imagen.');
    }
  };

  return (
    <div
      className="flex flex-col items-center space-y-4"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 w-full bg-white hover:border-blue-500 transition-colors">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
          id={id}
        />
        <label
          htmlFor={id}
          className="flex flex-col items-center space-y-4 cursor-pointer"
        >
          {previewURL ? (
            <>
              <img
                src={previewURL}
                alt="Preview"
                className="w-60 h-60 object-cover rounded-lg"
              />
              <div className="text-center">
                <p className="text-sm text-gray-500">
                  Haz clic para cambiar la imagen
                </p>
              </div>
            </>
          ) : (
            <>
              <MdImage size={60} className="text-gray-500" />
              <div className="text-center">
                <p className="text-lg font-semibold text-gray-700">
                  Arrastra y suelta tu imagen aquí
                </p>
                <p className="text-sm text-gray-500">
                  o haz clic para seleccionar
                </p>
              </div>
            </>
          )}
        </label>
      </div>
    </div>
  );
};

export default ImageUpload;
