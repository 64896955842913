import { FiArrowLeft, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Button from '../../button';

const HeaderDetailDocument = ({
  title,
  handleDelete,
}: {
  title: string;
  handleDelete: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center">
      <Button
        variant="link"
        label="Volver"
        onClick={() => navigate(-1)}
        icon={<FiArrowLeft className="w-4 h-4" />}
      />
      <h1 className="text-2xl font-bold">{title}</h1>
      <Button
        label="Eliminar"
        onClick={handleDelete}
        variant="ghost"
        icon={<FiTrash className="w-4 h-4" />}
      />
    </div>
  );
};

export default HeaderDetailDocument;
